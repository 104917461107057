import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Anchor, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
const { Option } = Select;
const { Link } = Anchor;

export default function TopStudentInfo() {
    const { Title, Paragraph, Text } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const [fileTypeChange, setFileTypeChange] = useState<any>();
    const [fileTypeChangeUpdate, setFileTypeChangeUpdate] = useState<any>();
    const storeTopStudentInfo = useStoreActions((state) => state.pages.storeTopStudentInfo);
    const fetchTopStudentInfoList = useStoreActions((state) =>  state.pages.fetchTopStudentInfoList);
    const topStudentInfoList = useStoreState((state) => state.pages.topStudentInfoList);
    const updateTopStudentInfo = useStoreActions((state) => state.pages.updateTopStudentInfo);
    const deleteTopStudentInfo = useStoreActions((state) => state.pages.deleteTopStudentInfo);

    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");
    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [studentId, setStudentId] = useState<any>("");
    
    const updateTopStudentInfoSubmit = (value) => {
        // console.log('value', value);
        
        let postDate = {
            "address": value?.addressUpdate,
            "examName": value?.examNameUpdate,
            "fatherName": value?.fatherNameUpdate,
            fileContent: fileContentUpdate,
            fileSave: fileContentUpdate == '' ? false : true,
            fileType: fileTypeUpdate,
            "motherName": value?.motherNameUpdate,
            "roll": value?.rollUpdate,
            "serial": value?.serialUpdate,
            "showStatus": value?.statusUpdate == 0 ? 1 : 0,
            "studentId": studentId,
            "studentName": value?.studentNameUpdate,
            "studentResult": value?.studentResultUpdate,
            "year": value?.yearUpdate
            }
        updateTopStudentInfo(postDate);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);

    }

    useEffect(() => {
        fetchTopStudentInfoList();
    },[]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deleteTopStudentInfoSubmit = (value) => {
        deleteTopStudentInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Files', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Anchor>
                    <Link href={record?.filePath} target="_blank" title={record?.fileName} />
                </Anchor>
            </>
        )},
        { title: 'Father Name', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Mother Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Exam Name', dataIndex: 'examName', key: 'examName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Year', dataIndex: 'year', key: 'year', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Id', dataIndex: 'studentId', key: 'studentId', showOnResponse: true, showOnDesktop: true  },
        { title: 'Roll', dataIndex: 'roll', key: 'roll', showOnResponse: true, showOnDesktop: true  },
        { title: 'Student Result', dataIndex: 'studentResult', key: 'studentResult', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'showStatusString', key: 'showStatusString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setStudentId(record.studentId);
                            updateForm.setFieldsValue({
                                addressUpdate: record?.address,
                                examNameUpdate: record?.examName,
                                fatherNameUpdate: record?.fatherName,
                                motherNameUpdate: record?.motherName,
                                rollUpdate: record?.roll,
                                serialUpdate: record?.serial,
                                studentNameUpdate: record?.studentName,
                                studentResultUpdate: record?.studentResult,
                                yearUpdate: record?.year
                            });
                            let fileTypeName = record?.fileName;
                            let fileTypeNameGen = fileTypeName.split('.')[1] ;
                            fileTypeNameGen == "pdf" || fileTypeNameGen == "docx" ? setFileTypeChangeUpdate("File") : setFileTypeChangeUpdate("Image");
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"  
                        onConfirm={() => deleteTopStudentInfoSubmit(record?.studentId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const dateFormat = 'YYYY-MM-DD';

    const topStudentInfoSubmit = (value) => {
        // console.log('value', value);
        
        let postData:any = {
            address: value?.address,
            examName: value?.examName,
            fatherName: value?.fatherName,
            fileContent: fileContent,
            fileSave: fileContent== '' ? false : true,
            fileType: fileType,
            motherName: value?.motherName,
            roll: value?.roll,
            serial: value?.serial,
            studentName: value?.studentName,
            studentResult: value?.studentResult,
            year: value?.year
        }
        storeTopStudentInfo(postData);
        form.resetFields();
        setfileContent("");
        setfileType("");
        setFileList([]);
    }   

    const onChangeFileType = (value) =>{
        setFileTypeChange(value);
        setfileContent("");
        setfileType("");
        setFileList([]);
    }

    const onChangeFileTypeUpdate = (value) =>{
        setFileTypeChangeUpdate(value);
    }

    return (
        <>
            <Card title="Top Student Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={topStudentInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="studentName"
                                        label="Student Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write student name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="examName"
                                        label="Exam Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write exam name" },
                                        ]} 
                                    >
                                        <Input placeholder="Exam Name" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="fatherName"
                                        label="Father Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write father name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write father name" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="motherName"
                                        label="Mother Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select mother name" },
                                        ]} 
                                    >
                                        <Input placeholder="select mother name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="roll"
                                        label="Roll"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select roll" },
                                        ]} 
                                    >
                                        <Input placeholder="select roll" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="studentResult"
                                        label="Student Resilt"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write student result" },
                                        ]} 
                                    >
                                        <Input placeholder="Write student result" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="year"
                                        label="Year"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write year" },
                                        ]} 
                                    >
                                        <Input placeholder="Write year" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="address"
                                        label="Address"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write address" },
                                        ]} 
                                    >
                                        <Input placeholder="Write address" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                                    <Spin tip="Uploading..." spinning={start?true:false}>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right"}}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: topStudentInfoList,
                                        filterData: topStudentInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Top Student Info Update"
                visible={isModalVisible}
                // onOk={updateTopStudentInfo}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                width="50%"
                okButtonProps={{ form: 'resultUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='resultUpdate' form={updateForm} onFinish={updateTopStudentInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="studentNameUpdate"
                                label="Student Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write student name" },
                                ]} 
                            >
                                <Input placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="examNameUpdate"
                                label="Exam Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write exam name" },
                                ]} 
                            >
                                <Input placeholder="Exam Name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="fatherNameUpdate"
                                label="Father Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write father name" },
                                ]} 
                            >
                                <Input placeholder="Write father name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="motherNameUpdate"
                                label="Mother Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select mother name" },
                                ]} 
                            >
                                <Input placeholder="select mother name" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="rollUpdate"
                                label="Roll"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select roll" },
                                ]} 
                            >
                                <Input placeholder="select roll" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="studentResultUpdate"
                                label="Student Resilt"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write student result" },
                                ]} 
                            >
                                <Input placeholder="Write student result" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="yearUpdate"
                                label="Year"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write year" },
                                ]} 
                            >
                                <Input placeholder="Write year" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="addressUpdate"
                                label="Address"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write address" },
                                ]} 
                            >
                                <Input placeholder="Write address" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="statusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status"> 
                                    <Option key={0} value="Yes">Yes</Option>
                                    <Option key={1} value="No">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                <Form.Item
                                    name="user_imageUpdate"
                                    label="File:"
                                    className="title-Text"
                                >
                                    <Upload
                                        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        accept="application/pdf,application/vnd.ms-word"
                                        fileList={fileList}
                                        onChange={onChangeFile}
                                        onPreview={onPreview}
                                    >
                                        {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                    </Upload>
                                </Form.Item>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}