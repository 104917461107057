import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { aboutUsInfoListUrl, aboutUsInfoSaveUrl, aboutUsInfoUpdateDeleteUrl, aboutUsInfoUpdateUrl, classRoutineInfoDeleteUrl, classRoutineInfoListUrl, classRoutineInfoSaveUrl, classRoutineInfoUpdateUrl, deleteEventInfoUrl, deleteGalleryInfoUrl, deleteMemberInfoUrl, deleteNoticeInfoUrl, deleteSpeechInfoUrl, eventInfoListUrl, eventInfoSaveUrl, eventInfoUpdateUrl, examRoutineDeleteUrl, examRoutineListUrl, examRoutineSaveUrl, examRoutineUpdateUrl, galleryInfoListUrl, galleryInfoSaveUrl, galleryInfoUpdateUrl, importantLinksInfoDeleteUrl, importantLinksInfoListUrl, importantLinksInfoSaveUrl, importantLinksInfoUpdateUrl, instiuteInfoListUpdate, instiuteInfoListUrl, memberInfoListUrl, memberInfoSaveUrl, memberInfoUpdateUrl, noticeInfoListUrl, noticeInfoSaveUrl, noticeInfoUpdateUrl, resultInfoDeleteUrl, resultInfoListUrl, resultInfoSaveUrl, resultInfoUpdateUrl, speechInfoListUrl, speechInfoSaveUrl, speechInfoUpdateUrl, statisticInfoDeleteUrl, statisticInfoListUrl, statisticInfoSaveUrl, statisticInfoUpdateUrl, syllabusInfoDeleteUrl, syllabusInfoListUrl, syllabusInfoSaveUrl, syllabusInfoUpdateUrl, topStudentDeleteUrl, topStudentListUrl, topStudentSaveUrl, topStudentUpdateUrl } from '../../../http/pages/pages';

export interface Pages {
	storeGallaryInfo : Thunk<Pages, any>;
	galleryInfoList: any;
	setGalleryInfoList: Action<Pages, any>;
	fetchGalleryInfoList: Thunk<Pages>;
	updateGallaryInfo : Thunk<Pages, any>;
	deleteGallaryInfo : Thunk<Pages, any>;

	storeNoticeInfo : Thunk<Pages, any>;
	noticeInfoList: any;
	setNoticeInfoList: Action<Pages, any>;
	fetchNoticeInfoList: Thunk<Pages>;
	updateNoticeInfo : Thunk<Pages, any>;
	deleteNoticeInfo : Thunk<Pages, any>;

	storememberInfo : Thunk<Pages, any>;
	memberInfoList: any;
	setMemberInfoList: Action<Pages, any>;
	fetchMemberInfoList: Thunk<Pages>;
	updateMemberInfo : Thunk<Pages, any>;
	deleteMemberInfo : Thunk<Pages, any>;

	instiuteInfoList: any;
	setInstiuteInfoList: Action<Pages, any>;
	fetchInstiuteInfoList: Thunk<Pages>;
	updateInstiuteInfo : Thunk<Pages, any>;

	memberInfoListForSpeech: any;
	setMemberInfoListForSpeech: Action<Pages, any>;
	fetchMemberInfoListForSpeech: Thunk<Pages>;

	storeSpeechInfo : Thunk<Pages, any>;
	speechInfoList: any;
	setSpeechInfoList: Action<Pages, any>;
	fetchSpeechInfoList: Thunk<Pages>;
	updateSpeechInfo : Thunk<Pages, any>;
	deleteSpeechInfo : Thunk<Pages, any>;

	storeEventInfo : Thunk<Pages, any>;
	eventInfoList: any;
	setEventInfoList: Action<Pages, any>;
	fetchEventInfoList: Thunk<Pages>;
	updateEventInfo : Thunk<Pages, any>;
	deleteEventInfo : Thunk<Pages, any>;

	storeImportantLinksInfo : Thunk<Pages, any>;
	importantLinksInfoList: any;
	setImportantLinksInfoList: Action<Pages, any>;
	fetchImportantLinksInfoList: Thunk<Pages>;
	updateImportantLinksInfo : Thunk<Pages, any>;
	deleteImportantLinksInfo : Thunk<Pages, any>;

	storeAboutUsInfo : Thunk<Pages, any>;
	aboutUsInfoList: any;
	setAboutUsInfoList: Action<Pages, any>;
	fetchAboutUsInfoList: Thunk<Pages>;
	updateAboutUsInfo : Thunk<Pages, any>;
	deleteAboutUsInfo : Thunk<Pages, any>;

	storeStatisticInfo : Thunk<Pages, any>;
	statisticInfoList: any;
	setStatisticInfoList: Action<Pages, any>;
	fetchStatisticInfoList: Thunk<Pages>;
	updateStatisticInfo : Thunk<Pages, any>;
	deleteStatisticInfo : Thunk<Pages, any>;

	storeResultInfo : Thunk<Pages, any>;
	resultInfoList: any;
	setResultInfoList: Action<Pages, any>;
	fetchResultInfoList: Thunk<Pages>;
	updateResultInfo : Thunk<Pages, any>;
	deleteResultInfo : Thunk<Pages, any>;

	storeTopStudentInfo : Thunk<Pages, any>;
	topStudentInfoList: any;
	setTopStudentInfoList: Action<Pages, any>;
	fetchTopStudentInfoList: Thunk<Pages>;
	updateTopStudentInfo : Thunk<Pages, any>;
	deleteTopStudentInfo : Thunk<Pages, any>;

	storeExamRoutineInfo : Thunk<Pages, any>;
	examRoutineInfoList: any;
	setExamRoutineInfoList: Action<Pages, any>;
	fetchExamRoutineInfoList: Thunk<Pages>;
	updateExamRoutineInfo : Thunk<Pages, any>;
	deleteExamRoutineInfo : Thunk<Pages, any>;

	storeSyllabusInfo : Thunk<Pages, any>;
	syllabusInfoList: any;
	setSyllabusInfoList: Action<Pages, any>;
	fetchSyllabusInfoList: Thunk<Pages>;
	updateSyllabusInfo : Thunk<Pages, any>;
	deleteSyllabusInfo : Thunk<Pages, any>;

	storeClassRoutineInfo : Thunk<Pages, any>;
	classRoutineInfoList: any;
	setClassRoutineInfoList: Action<Pages, any>;
	fetchClassRoutineInfoList: Thunk<Pages>;
	updateClassRoutineInfo : Thunk<Pages, any>;
	deleteClassRoutineInfo : Thunk<Pages, any>;
}	

export const pagesStore: Pages = {
	storeGallaryInfo:thunk(async (actions, payload) => {
		const response  = await galleryInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Gallery Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Gallery Info',
					description: body?.message,
				});
			}
			actions.fetchGalleryInfoList();
		}else{
			notification['error']({
				message: 'Gallery Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	galleryInfoList: null,
	fetchGalleryInfoList: thunk(async (actions, payload) => {
		const response = await galleryInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setGalleryInfoList(body?.item);
			} else {
				notification['success']({
					message: 'Gallery Info',
					description: "Gallery Info List",
				});
			}
		} else {
			notification['error']({
				message: 'Gallery Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setGalleryInfoList: action((state, payload) => {
		state.galleryInfoList = payload;
	}), 
	updateGallaryInfo:thunk(async (actions, payload) => {
		const response  = await galleryInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Gallery Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Gallery Info',
					description: body?.message,
				});
			}
			actions.fetchGalleryInfoList();
		}else{
			notification['error']({
				message: 'Gallery Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	deleteGallaryInfo:thunk(async (actions, payload) => {
		const response  = await deleteGalleryInfoUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Gallery Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Gallery Info',
					description: body?.message,
				});
			}
			actions.fetchGalleryInfoList();
		}else{
			notification['error']({
				message: 'Gallery Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	storeNoticeInfo:thunk(async (actions, payload) => {
		const response  = await noticeInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Notice Info',
					description: body?.message,
				});
				actions.fetchNoticeInfoList();
			} else {
				notification['error']({
					message: 'Notice Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Notice Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	noticeInfoList: null,
	fetchNoticeInfoList: thunk(async (actions, payload) => {
		const response = await noticeInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setNoticeInfoList(body?.item);
			} else {
				notification['error']({
					message: 'Notice Info',
					description: "Notice Info List not found",
				});
				actions.setNoticeInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Notice Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setNoticeInfoList: action((state, payload) => {
		state.noticeInfoList = payload;
	}),
	updateNoticeInfo:thunk(async (actions, payload) => {
		const response  = await noticeInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Notice Info',
					description: body?.message,
				});
				actions.fetchNoticeInfoList();
			} else {
				notification['error']({
					message: 'Notice Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Notice Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	deleteNoticeInfo:thunk(async (actions, payload) => {
		const response  = await deleteNoticeInfoUrl(payload);
		console.log('response', response);
		 
		if(response.status == 201){
			const body = await response.json();
			console.log('body', body);
			
			if (body.messageType == 1) {
				notification['success']({
					message: 'Notice Info',
					description: body?.message,
				});
				actions.fetchNoticeInfoList();
			} else {
				notification['error']({
					message: 'Notice Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Notice Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	storememberInfo:thunk(async (actions, payload) => {
		const response  = await memberInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Member Info',
					description: body?.message,
				});
				actions.fetchMemberInfoList();
			} else {
				notification['error']({
					message: 'Member Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Member Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	memberInfoList: null,
	fetchMemberInfoList: thunk(async (actions, payload) => {
		const response = await memberInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setMemberInfoList(body?.item);
			} else {
				notification['error']({
					message: 'Member Info',
					description: "Notice Info List not found",
				});
				actions.setMemberInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Member Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setMemberInfoList: action((state, payload) => {
		state.memberInfoList = payload;
	}),
	updateMemberInfo:thunk(async (actions, payload) => {
		const response  = await memberInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Member Info',
					description: body?.message,
				});
				actions.fetchMemberInfoList();
			} else {
				notification['error']({
					message: 'Member Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Member Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	deleteMemberInfo:thunk(async (actions, payload) => {
		const response  = await deleteMemberInfoUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Member Info',
					description: body?.message,
				});
				actions.fetchMemberInfoList();
			} else {
				notification['error']({
					message: 'Member Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Member Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	instiuteInfoList: null,
	fetchInstiuteInfoList: thunk(async (actions, payload) => {
		const response = await instiuteInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setInstiuteInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Gallery Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setInstiuteInfoList: action((state, payload) => {
		state.instiuteInfoList = payload;
	}),
	updateInstiuteInfo:thunk(async (actions, payload) => {
		const response  = await instiuteInfoListUpdate(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Insttiute Info',
					description: body?.message,
				});
				actions.fetchInstiuteInfoList();
			} else {
				notification['error']({
					message: 'Insttiute Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Institute Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	memberInfoListForSpeech: null,
	fetchMemberInfoListForSpeech: thunk(async (actions, payload) => {
		const response = await memberInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setMemberInfoListForSpeech(body?.item);
			} else {
				notification['error']({
					message: 'Member Info',
					description: "Notice Info List not found",
				});
				actions.setMemberInfoListForSpeech(body?.item);
			}
		} else {
			notification['error']({
				message: 'Member Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setMemberInfoListForSpeech: action((state, payload) => {
		state.memberInfoListForSpeech = payload;
	}),
	storeSpeechInfo:thunk(async (actions, payload) => {
		const response  = await speechInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Speech Info',
					description: body?.message,
				});
				actions.fetchSpeechInfoList();
			} else {
				notification['error']({
					message: 'Speech Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	speechInfoList: null,
	fetchSpeechInfoList: thunk(async (actions, payload) => {
		const response = await speechInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setSpeechInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setSpeechInfoList: action((state, payload) => {
		state.speechInfoList = payload;
	}),
	updateSpeechInfo:thunk(async (actions, payload) => {
		const response  = await speechInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Speech Info',
					description: body?.message,
				});
				actions.fetchSpeechInfoList();
			} else {
				notification['error']({
					message: 'Speech Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	deleteSpeechInfo:thunk(async (actions, payload) => {
		const response  = await deleteSpeechInfoUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Speech Info',
					description: body?.message,
				});
				actions.fetchSpeechInfoList();
			} else {
				notification['error']({
					message: 'Speech Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	storeEventInfo:thunk(async (actions, payload) => {
		const response  = await eventInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Event Info',
					description: body?.message,
				});
				actions.fetchEventInfoList();
			} else {
				notification['error']({
					message: 'Event Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Event Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	eventInfoList: [],
	fetchEventInfoList: thunk(async (actions, payload) => {
		const response = await eventInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setEventInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setEventInfoList: action((state, payload) => {
		state.eventInfoList = payload;
	}),
	updateEventInfo:thunk(async (actions, payload) => {
		const response  = await eventInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Event Info',
					description: body?.message,
				});
				actions.fetchEventInfoList();
			} else {
				notification['error']({
					message: 'Event Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Event Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteEventInfo:thunk(async (actions, payload) => {
		const response  = await deleteEventInfoUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Event Info',
					description: body?.message,
				});
				actions.fetchEventInfoList();
			} else {
				notification['error']({
					message: 'Event Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Event Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	storeImportantLinksInfo:thunk(async (actions, payload) => {
		const response  = await importantLinksInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Important Links Info',
					description: body?.message,
				});
				actions.fetchImportantLinksInfoList();
			} else {
				notification['error']({
					message: 'Important Links Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Important Links Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	importantLinksInfoList: [],
	fetchImportantLinksInfoList: thunk(async (actions, payload) => {
		const response = await importantLinksInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setImportantLinksInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setImportantLinksInfoList: action((state, payload) => {
		state.importantLinksInfoList = payload;
	}),
	updateImportantLinksInfo:thunk(async (actions, payload) => {
		const response  = await importantLinksInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Important Links Info',
					description: body?.message,
				});
				actions.fetchImportantLinksInfoList();
			} else {
				notification['error']({
					message: 'Important Links Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Important Links Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteImportantLinksInfo:thunk(async (actions, payload) => {
		const response  = await importantLinksInfoDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Important Links Info',
					description: body?.message,
				});
				actions.fetchImportantLinksInfoList();
			} else {
				notification['error']({
					message: 'Important Links Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Important Links Info',
				description: "Something Went Wrong",
			});
		}
		
	}),

	storeAboutUsInfo:thunk(async (actions, payload) => {
		const response  = await aboutUsInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'About Us Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'About Us Info',
					description: body?.message,
				});
			}
			actions.fetchAboutUsInfoList();
		}else{
			notification['error']({
				message: 'About Us Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	aboutUsInfoList: [],
	
	fetchAboutUsInfoList: thunk(async (actions, payload) => {
		const response = await aboutUsInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setAboutUsInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Speech Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setAboutUsInfoList: action((state, payload) => {
		state.aboutUsInfoList = payload;
	}),
	updateAboutUsInfo:thunk(async (actions, payload) => {
		const response  = await aboutUsInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'About Us Info',
					description: body?.message,
				});
				actions.fetchAboutUsInfoList();
			} else {
				notification['error']({
					message: 'About Us Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'About Us Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteAboutUsInfo:thunk(async (actions, payload) => {
		const response  = await aboutUsInfoUpdateDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'About Us Info',
					description: body?.message,
				});
				actions.fetchAboutUsInfoList();
			} else {
				notification['error']({
					message: 'About Us Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'About Us Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	statisticInfoList: [], 
	fetchStatisticInfoList: thunk(async (actions, payload) => {
		const response = await statisticInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setStatisticInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Statistic Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setStatisticInfoList: action((state, payload) => {
		state.statisticInfoList = payload;
	}),
	storeStatisticInfo:thunk(async (actions, payload) => {
		const response  = await statisticInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Statistic Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Statistic Info',
					description: body?.message,
				});
			}
			actions.fetchStatisticInfoList();
		}else{
			notification['error']({
				message: 'Statistic Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateStatisticInfo:thunk(async (actions, payload) => {
		const response  = await statisticInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Statistic Info',
					description: body?.message,
				});
				actions.fetchStatisticInfoList();
			} else {
				notification['error']({
					message: 'Statistic Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Statistic Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteStatisticInfo:thunk(async (actions, payload) => {
		const response  = await statisticInfoDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Statistic Info',
					description: body?.message,
				});
				actions.fetchStatisticInfoList();
			} else {
				notification['error']({
					message: 'Statistic Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Statistic Info',
				description: "Something Went Wrong",
			});
		}
		
	}),

	resultInfoList: [], 
	fetchResultInfoList: thunk(async (actions, payload) => {
		const response = await resultInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setResultInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Result Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setResultInfoList: action((state, payload) => {
		state.resultInfoList = payload;
	}),
	storeResultInfo:thunk(async (actions, payload) => {
		const response  = await resultInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Result Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Result Info',
					description: body?.message,
				});
			}
			actions.fetchResultInfoList();
		}else{
			notification['error']({
				message: 'Result Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateResultInfo:thunk(async (actions, payload) => {
		const response  = await resultInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Result Info',
					description: body?.message,
				});
				actions.fetchResultInfoList();
			} else {
				notification['error']({
					message: 'Result Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Result Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteResultInfo:thunk(async (actions, payload) => {
		const response  = await resultInfoDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Result Info',
					description: body?.message,
				});
				actions.fetchResultInfoList();
			} else {
				notification['error']({
					message: 'Result Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Result Info',
				description: "Something Went Wrong",
			});
		}
		
	}),

	topStudentInfoList: [], 
	fetchTopStudentInfoList: thunk(async (actions, payload) => {
		const response = await topStudentListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setTopStudentInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Result Info',
				description: "Something Went Wrong",
			});
		}
	}),
	setTopStudentInfoList: action((state, payload) => {
		state.topStudentInfoList = payload;
	}),
	storeTopStudentInfo:thunk(async (actions, payload) => {
		const response  = await topStudentSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'To Student Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'To Student Info',
					description: body?.message,
				});
			}
			actions.fetchTopStudentInfoList();
		}else{
			notification['error']({
				message: 'To Student Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateTopStudentInfo:thunk(async (actions, payload) => {
		const response  = await topStudentUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'To Student Info',
					description: body?.message,
				});
				actions.fetchTopStudentInfoList();
			} else {
				notification['error']({
					message: 'To Student Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'To Student Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteTopStudentInfo:thunk(async (actions, payload) => {
		const response  = await topStudentDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'To Student Info',
					description: body?.message,
				});
				actions.fetchTopStudentInfoList();
			} else {
				notification['error']({
					message: 'To Student Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'To Student Info',
				description: "Something Went Wrong",
			});
		}
		
	}),

	examRoutineInfoList: [], 
	fetchExamRoutineInfoList: thunk(async (actions, payload) => {
		const response = await examRoutineListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setExamRoutineInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Exam Routine',
				description: "Something Went Wrong",
			});
		}
	}),
	setExamRoutineInfoList: action((state, payload) => {
		state.examRoutineInfoList = payload;
	}),
	storeExamRoutineInfo:thunk(async (actions, payload) => {
		const response  = await examRoutineSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
			}
			actions.fetchExamRoutineInfoList();
		}else{
			notification['error']({
				message: 'Exam Routine Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateExamRoutineInfo:thunk(async (actions, payload) => {
		const response  = await examRoutineUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
				actions.fetchExamRoutineInfoList();
			} else {
				notification['error']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Exam Routine Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteExamRoutineInfo:thunk(async (actions, payload) => {
		const response  = await examRoutineDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
				actions.fetchExamRoutineInfoList();
			} else {
				notification['error']({
					message: 'Exam Routine Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Exam Routine Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	setSyllabusInfoList: action((state, payload) => {
		state.syllabusInfoList = payload;
	}),
	syllabusInfoList: [], 
	fetchSyllabusInfoList: thunk(async (actions, payload) => {
		const response = await syllabusInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setSyllabusInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Syllabus Routine',
				description: "Something Went Wrong",
			});
		}
	}),
	storeSyllabusInfo:thunk(async (actions, payload) => {
		const response  = await syllabusInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Syllabus Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Syllabus Info',
					description: body?.message,
				});
			}
			actions.fetchSyllabusInfoList();
		}else{
			notification['error']({
				message: 'Syllabus Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateSyllabusInfo:thunk(async (actions, payload) => {
		const response  = await syllabusInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Syllabus Info',
					description: body?.message,
				});
				actions.fetchSyllabusInfoList();
			} else {
				notification['error']({
					message: 'Syllabus Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Syllabus Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteSyllabusInfo:thunk(async (actions, payload) => {
		const response  = await syllabusInfoDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Syllabus Info',
					description: body?.message,
				});
				actions.fetchSyllabusInfoList();
			} else {
				notification['error']({
					message: 'Syllabus Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Syllabus Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	setClassRoutineInfoList: action((state, payload) => {
		state.classRoutineInfoList = payload;
	}),
	classRoutineInfoList: [], 
	fetchClassRoutineInfoList: thunk(async (actions, payload) => {
		const response = await classRoutineInfoListUrl();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType == 1) {
				actions.setClassRoutineInfoList(body?.item);
			}
		} else {
			notification['error']({
				message: 'Class Routine Routine',
				description: "Something Went Wrong",
			});
		}
	}),
	storeClassRoutineInfo:thunk(async (actions, payload) => {
		const response  = await classRoutineInfoSaveUrl(payload); 
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Class Routine Info',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'Class Routine Info',
					description: body?.message,
				});
			}
			actions.fetchClassRoutineInfoList();
		}else{
			notification['error']({
				message: 'Class Routine Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
	updateClassRoutineInfo:thunk(async (actions, payload) => {
		const response  = await classRoutineInfoUpdateUrl(payload); 
		if(response.status == 201){
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Class Routine Info',
					description: body?.message,
				});
				actions.fetchClassRoutineInfoList();
			} else {
				notification['error']({
					message: 'Class Routine Info',
					description: body?.message,
				});
			}
			
		}else{
			notification['error']({
				message: 'Class Routine Info',
				description: "Something Went Wrong",
			});
		}
	}),
	deleteClassRoutineInfo:thunk(async (actions, payload) => {
		const response  = await classRoutineInfoDeleteUrl(payload);
		if(response.status == 201){
			const body = await response.json();
			if (body.messageType == 1) {
				notification['success']({
					message: 'Class Routine Info',
					description: body?.message,
				});
				actions.fetchClassRoutineInfoList();
			} else {
				notification['error']({
					message: 'Class Routine Info',
					description: body?.message,
				});
			}
		}else{
			notification['error']({
				message: 'Class Routine Info',
				description: "Something Went Wrong",
			});
		}
		
	}),
}
