import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import moment from 'moment';
const { Option } = Select;

export default function SpeaceInfo() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const storeEventInfo = useStoreActions((state) => state.pages.storeEventInfo);
    const fetchEventInfoList = useStoreActions((state) => state.pages.fetchEventInfoList);
    const eventInfoList = useStoreState((state) => state.pages.eventInfoList);
    const updateEventInfo = useStoreActions((state) => state.pages.updateEventInfo);
    const deleteEventInfo = useStoreActions((state) => state.pages.deleteEventInfo);
    const [eventId,setEventId] = useState<any>() 

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    useEffect(() => {
        fetchEventInfoList();
    }, []);

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Title', dataIndex: 'title', key: 'title', showOnResponse: true, showOnDesktop: true  },
        { title: 'Details', dataIndex: 'details', key: 'details', showOnResponse: true, showOnDesktop: true  },
        { title: 'Issue Date', dataIndex: 'issueDate', key: 'issueDate', showOnResponse: true, showOnDesktop: true  },
        { title: 'Expiry Date', dataIndex: 'expiryDate', key: 'expiryDate', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'eventStatusString', key: 'eventStatusString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setEventId(record.eventId);
                            updateForm.setFieldsValue({
                                serialUpdate : record.serial,
                                typeUpdate : record.type,
                                titleUpdate : record.title,
                                subTitleUpdate : record.subTitle,
                                detailsUpdate : record.details,
                                expiryDateUpdate: moment(record.expiryDate),
                                issueDateUpdate: moment(record.issueDate),
                                eventStatusUpdate:record.eventStatusString
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteEventInfoSubmit(record.eventId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const dateFormat = 'YYYY-MM-DD';

    const eventInfoSubmit = (value) => {
        let postdata = {
            details: value?.details,
            eventStatus: value.statusUpdate == "enable" ? 1 : 0,
            expiryDate: moment(value?.expiryDate).format("YYYY-MM-DD"),
            issueDate: moment(value?.issueDate).format("YYYY-MM-DD"),
            serial: value?.serial,
            subTitle: value?.subTitle,
            title: value?.title,
            type: value?.type,
        }
        storeEventInfo(postdata);
        form.resetFields();
    }

    const eventInfoUpdate = (value) => {
        let postdata = {
            details: value?.detailsUpdate,
            eventId: eventId,
            eventStatus: value?.eventStatusUpdate == "enable" ? 1 : 0,
            expiryDate: moment(value?.expiryDateUpdate).format(dateFormat),
            issueDate: moment(value?.issueDateUpdate).format(dateFormat),
            serial: value?.serialUpdate,
            subTitle: value?.subTitleUpdate,
            title: value?.titleUpdate,
            type: value?.typeUpdate
        }

        updateEventInfo(postdata);
        setIsModalVisible(false);
    }

    const deleteEventInfoSubmit = (value) => {
        deleteEventInfo(value);
    } 



    return (
        <>
            <Card title="Event Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={eventInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="type"
                                        label="Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select type">
                                            <Option key={0} value="Holiday">Holiday</Option>
                                            <Option key={1} value="General Holiday">General Holiday</Option>
                                            <Option key={2} value="Exam Day">Exam Day</Option>
                                            <Option key={3} value="Event">Event</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="subTitle"
                                        label="Sub Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write sub title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write sub title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="issueDate"
                                        label="Issue Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select issue date" },
                                        ]} 
                                    >
                                        <DatePicker placeholder="select issue date" style={{ width: "100%" }}/> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="expiryDate"
                                        label="Expiry Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select expiry date" },
                                        ]} 
                                    >
                                        <DatePicker placeholder="select expiry date" style={{ width: "100%" }}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="details"
                                        label="Details"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write details" },
                                        ]} 
                                    >
                                        <Input.TextArea placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select status" },
                                        ]} 
                                    >
                                        <Select placeholder="Select status" >
                                            <Option key={0} value="enable">Enable</Option>
                                            <Option key={1} value="disable">Disable</Option>
                                        </Select> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right" }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: eventInfoList,
                                        filterData: eventInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Event Info Update"
                visible={isModalVisible}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                width="50%"
                okButtonProps={{ form: 'eventUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='eventUpdate' form={updateForm} onFinish={eventInfoUpdate}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="typeUpdate"
                                label="Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select type" },
                                ]} 
                            >
                                <Select placeholder="Select type">
                                    <Option key={0} value="Holiday">Holiday</Option>
                                    <Option key={1} value="General Holiday">General Holiday</Option>
                                    <Option key={2} value="Exam Day">Exam Day</Option>
                                    <Option key={3} value="Event">Event</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="titleUpdate"
                                label="Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="subTitleUpdate"
                                label="Sub Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write sub title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="issueDateUpdate"
                                label="Issue Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select issue date" },
                                ]} 
                            >
                                <DatePicker placeholder="select issue date" style={{ width: "100%" }} format={dateFormat} /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="expiryDateUpdate"
                                label="Expiry Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select expiry date" },
                                ]} 
                            >
                                <DatePicker placeholder="select expiry date" style={{ width: "100%" }} format={dateFormat} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="detailsUpdate"
                                label="Details"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write details" },
                                ]} 
                            >
                                <Input.TextArea placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="eventStatusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" >
                                    <Option key={0} value="enable">Enable</Option>
                                    <Option key={1} value="disable">Disable</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}