import { EditFilled, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Modal, Row, Spin, Typography, Upload, Select } from 'antd'
import ImgCrop from 'antd-img-crop';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import React, { useEffect, useState } from 'react'
const { Option } = Select;

export default function InstituteInfo() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const fetchInstiuteInfoList = useStoreActions((state) => state.pages.fetchInstiuteInfoList);
    const instiuteInfoList = useStoreState((state) => state.pages.instiuteInfoList);
    const updateInstiuteInfo = useStoreActions((state) => state.pages.updateInstiuteInfo);
    const [start, setStart] = useState<boolean>(false);
    const [startBanner, setStartBanner] = useState<boolean>(false);

    const [fileBannerList, setFileBannerList] = useState<any>([]);
    const [fileBannerSave, setfileBannerSave] = useState<boolean>(false);
    const [fileBannerName, setfileBannerName] = useState<any>("");
    const [fileBannerContent, setfileBannerContent] = useState<any>("");
    const [fileBannerType, setfileBannerType] = useState<any>("");

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    useEffect(() => {
        fetchInstiuteInfoList();
    }, [])

    const updateFormView = (value) => {
        // console.log('value', value);
        form.setFieldsValue({
            adminName: instiuteInfoList?.adminName,
            name: instiuteInfoList?.cmsName,
            address: instiuteInfoList?.address,
            phone: instiuteInfoList?.contactNumber,
            emailAddress: instiuteInfoList?.cmsEmail,
            website: instiuteInfoList?.webSite,
            facebook: instiuteInfoList?.linkFacebook,
            twitter: instiuteInfoList?.linkTwitter,
            linkedin: instiuteInfoList?.linkLinkedin,
            instituteId: instiuteInfoList?.cmsId,
            shebaInstituteType: instiuteInfoList?.shebaInstituteType,
            shebaInstituteId: instiuteInfoList?.shebaInstituteId,
            schoolCollegeAdmissionLink: instiuteInfoList?.schoolCollegeAdmissionLink,
            universityAdmissionLink: instiuteInfoList?.universityAdmissionLink,
            paymentLink: instiuteInfoList?.paymentLink,
        });
        setIsModalVisible(true)
    }

    const updateInstiuteInfoSubmit = (value) => {
        // console.log('value', value);
        let postDate = {
            address: value.address,
            adminName: value.adminName,
            cmsEmail: value.emailAddress,
            cmsName: value.name,
            contactNumber: value.phone,
            imageContent: fileContent,
            imageName: fileName,
            imageSave: fileContent == '' ? false : true,
            imageType: fileType,
            imagelink: "vv",
            linkFacebook: value?.facebook,
            universityAdmissionLink: value?.universityAdmissionLink,
            paymentLink: value?.paymentLink,
            schoolCollegeAdmissionLink: value?.schoolCollegeAdmissionLink,
            linkLinkedin: value?.linkedin,
            linkTwitter: value?.twitter,
            webSite: value?.website,
            shebaInstituteId: value?.shebaInstituteId,
            shebaInstituteType: value?.shebaInstituteType,

            bannerContent: fileBannerContent,
            bannerSave: fileBannerContent == '' ? false : true,
            bannerType: fileBannerType,
        }
        updateInstiuteInfo(postDate);
        setIsModalVisible(false)
    }

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onBannerPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeBannerFile = ({ fileList: newFileBannerList }) => {
        if (newFileBannerList.length == 1) {
            setStartBanner(true);
            newFileBannerList[0].status = "done"
            setfileBannerSave(true);
            setfileBannerName(newFileBannerList[0]?.name);
            setfileBannerType(newFileBannerList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileBannerList[0]?.originFileObj);
            setTimeout(() => {
                setfileBannerContent(reader.result.split(',')[1]);
                setStartBanner(false);
            }, 2000);

        }
        setFileBannerList(newFileBannerList);
    };


    return (
        <>
            <Card title="CMS Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <Button type="primary" icon={<EditFilled />} className="float-right" onClick={(e) => updateFormView(e)} >Update Institute Info</Button>
                    </Col>
                </Row>
                <Row className='mt-30'>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 4 }}>
                        <Card className='box-shadow-none' style={{width:185, height:185}}>
                            {instiuteInfoList?.imagelink &&
                                <img
                                    style={{maxWidth:150, maxHeight:150}}
                                    src={instiuteInfoList?.imagelink}
                                />
                            }

                        </Card>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 20 }}>
                        <Card className='box-shadow-none'>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }}>
                                    <div>
                                        <Typography>
                                            <Title level={5}>Admin Name</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.adminName}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography>
                                            <Title level={5}>Name</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.cmsName}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Address</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.address}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>ID</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.cmsId}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Payment Link</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.paymentLink}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }}>
                                    <div>
                                        <Typography>
                                            <Title level={5}>Phone</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.contactNumber}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Email</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.cmsEmail}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Web</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.webSite}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>School College Admission Link</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.schoolCollegeAdmissionLink}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }}>
                                    <div>
                                        <Typography>
                                            <Title level={5}>Facebook</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.linkFacebook}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Twitter</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.linkTwitter}
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>Linkedin</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.linkLinkedin}
                                            </Paragraph>
                                        </Typography>
                                    </div>

                                    <div className='mt-15'>
                                        <Typography>
                                            <Title level={5}>University Admission Link</Title>
                                            <Paragraph>
                                                {instiuteInfoList?.universityAdmissionLink}
                                            </Paragraph>
                                        </Typography>
                                    </div>

                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card>

            <Modal title="Institute Info Update"
                visible={isModalVisible}
                onCancel={handleCancel}
                centered
                okText={"Submit"}
                width="50%"
                okButtonProps={{ form: 'instiuteInfo', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='instiuteInfo' form={form} onFinish={updateInstiuteInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="adminName"
                                label="Admin Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write admin name" },
                                ]}
                            >
                                <Input type="text" placeholder="Write admin name" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="name"
                                label="Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write name" },
                                ]}
                            >
                                <Input type="text" placeholder="Write name" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="address"
                                label="Address"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write address" },
                                ]}
                            >
                                <Input type="text" placeholder="Write address" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Spin tip="Uploading..." spinning={start ? true : false}>
                                <Form.Item
                                    name="user_image"
                                    label="Photo:"
                                    className="title-Text"
                                >
                                    <ImgCrop rotate>
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="image/png, image/jpeg"
                                            fileList={fileList}
                                            onChange={onChangeFile}
                                            onPreview={onPreview}
                                        >
                                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </Spin>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="phone"
                                label="Phone"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write phone" },
                                ]}
                            >
                                <Input type="text" placeholder="Write phone" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="emailAddress"
                                label="E-Mail Address"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write e-mail address" },
                                ]}
                            >
                                <Input type="text" placeholder="Write e-mail address" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="website"
                                label="Website"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write website link" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="facebook"
                                label="Facebook"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write facebook link" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="twitter"
                                label="Twitter"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write twitter link" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="linkedin"
                                label="Linkedin"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write facebook linkedin" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="instituteId"
                                label="Institute Id"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write institute ID" },
                                ]}
                            >
                                <Input type="text" placeholder="Write twitter institute ID" disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="shebaInstituteId"
                                label="Sheba Institute Id"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write sheba institute ID" },
                                ]}
                            >
                                <Input type="text" placeholder="Write twitter sheba institute ID" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="shebaInstituteType"
                                label="Sheba Institute Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Select sheba institute type" >
                                    <Option key={0} value="SHEBA_SCHOOL">SHEBA_SCHOOL</Option>
                                    <Option key={1} value="SHEBA_UNIVERSITY">SHEBA_UNIVERSITY</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="schoolCollegeAdmissionLink"
                                label="School College Admission Link"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="School College Admission Link" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="universityAdmissionLink"
                                label="University Admission Link"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="University Admission Link" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }}>
                            <Form.Item
                                name="paymentLink"
                                label="Payment Link"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Payment Link" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Spin tip="Uploading..." spinning={startBanner ? true : false}>
                                <Form.Item
                                    name="banner_image"
                                    label="Banner image"
                                    className="title-Text"
                                >
                                    <Upload
                                        listType="picture"
                                        accept="image/png, image/jpeg"
                                        fileList={fileBannerList}
                                        onChange={onChangeBannerFile}
                                        onPreview={onBannerPreview}
                                    >
                                        {fileBannerList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                    </Upload>
                                </Form.Item>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}