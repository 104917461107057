import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth/auth';
import { Common, commonStore } from './states/common/common';
import { Pages, pagesStore } from './states/pages/pages';
import { User, userStore } from './states/user/user';

export interface StoreModel {
	auth: Auth;
	common: Common;
	user: User;
	pages: Pages;

}

const storeModel: StoreModel = {
	auth: authStore,
	common: commonStore,
	user: userStore,
	pages: pagesStore,

}

export const store = createStore(storeModel);
