import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { AuditOutlined, CalculatorOutlined, ContainerOutlined, DashboardTwoTone, DeleteOutlined, DollarCircleOutlined, ExpandOutlined, FileFilled, FileTextOutlined, FundProjectionScreenOutlined, FundViewOutlined, PartitionOutlined, SolutionOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons/lib';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { ROUTES } from "../../contents/routes";

export function Sidebar() {
	// console.log(window.location.pathname)
	const fetchInstituteBasicData = useStoreActions((state) => state.auth.fetchInstituteBasicData);
	const instituteInfo = useStoreState((state) => state.auth.instituteBasicData);
	
	useEffect(() => {
		fetchInstituteBasicData();
	  },[])

	const [openkeys, setopenkeys] = useState<any>('')
	useEffect(() => {
		//fetchpartnerProfile();
		setpathValue(window.location.pathname.slice(1));
		if (window.location.pathname.slice(1) === '') {
			setpathValue('dashboard')
		}
		let temp = window.location.pathname.substr(window.location.pathname.lastIndexOf("-") + 1).toString();
		// console.log(temp)
		setopenkeys(temp)
	}, []);

	const [pathValue, setpathValue] = useState<any>("1")
	const [mainpathValue, setmainpathValue] = useState<any>(null)

	// console.log(pathValue)

	//const key = window.location.pathname === '/' ? 'home' : window.location.pathname.slice(1, location.pathname.length)
	//console.log(window.location.pathname)
	const [keys, setKeys] = useState<any>([]);
	const sideClick = (value) => {
		setpathValue(value.key)
		setKeys(value.keyPath)
		localStorage.setItem('sideBarValues', JSON.stringify(value.keyPath));
		localStorage.setItem('sideBarValuesKey', (value.key));
	}
	useEffect(() => {
		let val: any = localStorage.getItem('sideBarValues');
		let val2: any = localStorage.getItem('sideBarValuesKey');
		if (val !== null) {
			setRefresh(JSON.parse(val))
			setKeys(JSON.parse(val))
		}
		if (val2 !== null) {
			setpathValue(val2)
		}
		setView(true)
		//	console.log('here')
	}, []);

	const [refreshKeys, setRefresh] = useState<any>([]);
	const [view, setView] = useState<any>(false);

	const onOpenChange = (value) => {
		setKeys(value)
	}
	useEffect(() => {

		let val: any = localStorage.getItem('openKeys');
		if (val !== null) {
			setRefresh(JSON.parse(val))
		}
		setView(true)
	}, [])



	return <> {view && <Menu
		theme="dark"
		defaultSelectedKeys={[pathValue]}
		mode="inline"
		defaultOpenKeys={refreshKeys}
		selectedKeys={[pathValue]}
		onClick={sideClick}
		onOpenChange={onOpenChange}
		openKeys={keys}

	>

		<Menu.Item key={ROUTES.DEFAULT} icon={<DashboardTwoTone />}>
			<Link to={ROUTES.DEFAULT} className="nav-text">Dashboard</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.INSTITUTE_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.INSTITUTE_INFO} className="nav-text">CMS Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.GALLERY_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.GALLERY_INFO} className="nav-text">Gallery Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.ABOUTUS_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.ABOUTUS_INFO} className="nav-text">About Us Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.EXAM_ROUTINE_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.EXAM_ROUTINE_INFO} className="nav-text">Exam Routine</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.RESULT_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.RESULT_INFO} className="nav-text">Result Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.NOTICE_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.NOTICE_INFO} className="nav-text">Notice</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.SYLLABUS_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.SYLLABUS_INFO} className="nav-text">Syllabus Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.CLASS_ROUTINE} icon={<FileTextOutlined />}>
			<Link to={ROUTES.CLASS_ROUTINE} className="nav-text">Class Routine</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.EVENT_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.EVENT_INFO} className="nav-text">Events</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.IMPORTANT_LINK_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.IMPORTANT_LINK_INFO} className="nav-text">Important Links</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.MEMBER_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.MEMBER_INFO} className="nav-text">Member Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.SPEECH_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.SPEECH_INFO} className="nav-text">Speech Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.STATISTIC_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.STATISTIC_INFO} className="nav-text">Statistic Info</Link>
		</Menu.Item>
		<Menu.Item key={ROUTES.TOP_STUDENT_INFO} icon={<FileTextOutlined />}>
			<Link to={ROUTES.TOP_STUDENT_INFO} className="nav-text">Top Student Info</Link>
		</Menu.Item>
		{instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT') &&
			<>
				<hr style={{ width: "80%", marginTop: 30 }} />
				{/* <Divider style={{color:'white'}}> <hr  /></Divider> */}
				<SubMenu key={"customerSupport"} icon={<ExpandOutlined />} title="Customer Support" >
					<Menu.Item key={ROUTES.INSTITUTE_LIST} icon={<FileTextOutlined />}>
						<Link to={ROUTES.INSTITUTE_LIST} className="nav-text">Jump </Link>
					</Menu.Item>
				</SubMenu>

			</>
		}
	</Menu>
	}
	</>

}
