import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Anchor, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
const { Option } = Select;
const { Link } = Anchor;

export default function NoticeInfo() {
    const { Title, Paragraph, Text } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const [fileTypeChange, setFileTypeChange] = useState<any>();
    const [fileTypeChangeUpdate, setFileTypeChangeUpdate] = useState<any>();
    const storeNoticeInfo = useStoreActions((state) => state.pages.storeNoticeInfo);
    const fetchNoticeInfoList = useStoreActions((state) =>  state.pages.fetchNoticeInfoList);
    const noticeInfoList = useStoreState((state) => state.pages.noticeInfoList);
    const updateNoticeInfo = useStoreActions((state) => state.pages.updateNoticeInfo);
    const deleteNoticeInfo = useStoreActions((state) => state.pages.deleteNoticeInfo);

    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");
    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [noticeId, setNoticeId] = useState<any>("");

    const updateNoticeInfoSubmit = (value) => {
        let postDate = {
            details: value?.detailsUpdate,
            expiryDate: moment(value?.expiryDateUpdate).format(dateFormat),
            fileContent: fileContentUpdate,
            fileSave: fileContentUpdate == '' ? false : true,
            fileType: fileTypeUpdate,
            issueDate: moment(value?.issueDateUpdate).format(dateFormat),
            noticeId: noticeId,
            noticeStatus: value.noticeStatus == "Deactive" ? 0 : 1,
            serial: value?.serialUpdate,
            subTitle: value?.subTitleUpdate,
            title: value?.titleUpdate
        }
        updateNoticeInfo(postDate);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);

    }

    useEffect(() => {
        fetchNoticeInfoList();
    },[]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deleteNoticeInfoSubmit = (value) => {
        deleteNoticeInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Title', dataIndex: 'title', key: 'title', showOnResponse: true, showOnDesktop: true  },
        { title: 'Files', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Anchor>
                    <Link href={record?.filePath} target="_blank" title={record?.fileName} />
                </Anchor>
            </>
        )},
        { title: 'Date', dataIndex: 'date', key: 'date', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <div style={{ width: "100px" }}>
                <span>{record?.issueDate} - {record?.expiryDate}</span>
            </div>
        )},
        { title: 'Details', dataIndex: 'details', key: 'details', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'noticeStatus', key: 'noticeStatus', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setNoticeId(record.noticeId);
                            updateForm.setFieldsValue({
                                serialUpdate : record.serial,
                                typeUpdate : record.type,
                                titleUpdate : record.title,
                                subTitleUpdate : record.subTitle,
                                detailsUpdate : record.details,
                                expiryDateUpdate: moment(record.expiryDate),
                                issueDateUpdate: moment(record.issueDate),
                                noticeStatus:record.noticeStatus
                            });
                            let fileTypeName = record?.fileName;
                            let fileTypeNameGen = fileTypeName.split('.')[1] ;
                            fileTypeNameGen == "pdf" || fileTypeNameGen == "docx" ? setFileTypeChangeUpdate("File") : setFileTypeChangeUpdate("Image");
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"  
                        onConfirm={() => deleteNoticeInfoSubmit(record?.noticeId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const dateFormat = 'YYYY-MM-DD';

    const noticeInfoSubmit = (value) => {
        let postData:any = {
            details: value.details,
            expiryDate: moment(value?.expiryDate).format("YYYY-MM-DD"),
            fileContent: fileContent,
            fileSave: true,
            fileType: fileType, 
            issueDate: moment(value?.issueDate).format("YYYY-MM-DD"),
            serial: value?.serial,
            subTitle: value?.subTitle,
            title: value?.title
        }
        storeNoticeInfo(postData);
        form.resetFields();
        setfileContent("");
        setfileType("");
        setFileList([]);
    }   

    const onChangeFileType = (value) =>{
        setFileTypeChange(value);
        setfileContent("");
        setfileType("");
        setFileList([]);
    }

    const onChangeFileTypeUpdate = (value) =>{
        setFileTypeChangeUpdate(value);
    }

    return (
        <>
            <Card title="Notice Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={noticeInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="subTitle"
                                        label="Sub Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write sub title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write sub title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="issueDate"
                                        label="Issue Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select issue date" },
                                        ]} 
                                    >
                                        <DatePicker format={dateFormat} placeholder="select issue date" style={{ width: "100%" }}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="expiryDate"
                                        label="Expiry Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select expiry date" },
                                        ]} 
                                    >
                                        <DatePicker format={dateFormat} placeholder="select expiry date" style={{ width: "100%" }}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="details"
                                        label="Details"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write details" },
                                        ]} 
                                    >
                                        <Input.TextArea placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="fileType"
                                        label="Upload File Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please Select Upload File Type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select File Type" onChange={(e) => onChangeFileType(e)}>
                                            <Option key={0} value="File">File</Option>
                                            <Option key={1} value="Image">Image</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {fileTypeChange == "Image" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                }
                                {fileTypeChange == "File" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="File:"
                                            className="title-Text"
                                        >
                                            <Upload
                                                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture"
                                                accept="application/pdf,application/vnd.ms-word"
                                                fileList={fileList}
                                                onChange={onChangeFile}
                                                onPreview={onPreview}
                                            >
                                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right", marginTop: 0 }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: noticeInfoList,
                                        filterData: noticeInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Notice Info Update"
                visible={isModalVisible}
                // onOk={updateNoticeInfo}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                width="50%"
                okButtonProps={{ form: 'noticeUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='noticeUpdate' form={updateForm} onFinish={updateNoticeInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="titleUpdate"
                                label="Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="subTitleUpdate"
                                label="Sub Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write sub title" },
                                ]} 
                            >
                                <Input placeholder="Write sub title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="issueDateUpdate"
                                label="Issue Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select issue date" },
                                ]} 
                            >
                                <DatePicker format={dateFormat} placeholder="select issue date" style={{ width: "100%" }}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="expiryDateUpdate"
                                label="Expiry Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select expiry date" },
                                ]} 
                            >
                                <DatePicker placeholder="select expiry date" style={{ width: "100%" }} format={dateFormat}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="noticeStatus"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select notice status" },
                                ]} 
                            >
                                <Select placeholder="Select notice status" onChange={(e) => onChangeFileTypeUpdate(e)}>
                                    <Option key={0} value="Active">Active</Option>
                                    <Option key={1} value="Deactive">Deactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="detailsUpdate"
                                label="Details"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write details" },
                                ]} 
                            >
                                <Input.TextArea placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="fileType"
                                label="Upload File Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Select File Type" onChange={(e) => onChangeFileTypeUpdate(e)} defaultValue={fileTypeChangeUpdate}>
                                    <Option key={0} value="File">File</Option>
                                    <Option key={1} value="Image">Image</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {fileTypeChangeUpdate == "Image" &&
                            <Col>
                                <Form.Item
                                    name="user_image_update"
                                    label="Photo:"
                                    className="title-Text"
                                >
                                    <ImgCrop rotate>
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListUpdate}
                                            onChange={onChangeFileUpdate}
                                            onPreview={onPreviewUpdate}
                                        >
                                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </Col>
                        }
                        {fileTypeChangeUpdate == "File" &&
                            <Col>
                                <Form.Item
                                    name="user_image_update"
                                    label="File:"
                                    className="title-Text"
                                >
                                    <Upload
                                        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        accept="application/pdf,application/vnd.ms-word"
                                        fileList={fileListUpdate}
                                        onChange={onChangeFileUpdate}
                                        onPreview={onPreviewUpdate}
                                    >
                                        {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </Form>
            </Modal>

        </>
    )
}