
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import { useHistory } from "react-router-dom";
import { SwitcherOutlined } from '@ant-design/icons';

export default function InstituteList(props) {

    const history = useHistory();
    const instituteList = useStoreState((state) => state.common.instituteList);
    const fetchinstituteList = useStoreActions((state) => state.common.fetchinstituteList);
    const instituteInfo = useStoreState((state) => state.auth.instituteBasicData);
    const fetchJumpInstiute = useStoreActions((state) => state.auth.fetchJumpInstiute);

    useEffect(() => {
        fetchinstituteList();
    }, [])

    useEffect(() => {
        if (instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT')) {
            console.log("ok")
        } else {
            history.push('/')
        }
    }, [])

    const columns = [

        {
            title: 'CMS Id',
            dataIndex: 'cmsId',
            key: 'cmsId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'CMS Name',
            dataIndex: 'cmsName',
            key: 'cmsName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Admin',
            dataIndex: 'adminName',
            key: 'adminName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: "Status",
            dataIndex: 'cmsStatus',
            key: 'cmsStatus',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record: any, index) =>
            (
                <>
                    <span>{record.cmsStatus == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Jump">
                            <Button type='primary' icon={<SwitcherOutlined />} onClick={() => fetchJumpInstiute(record?.cmsId)} />
                        </Tooltip>
                    </Space>


                </>
        },

    ];



    return (
        <>
            <Card title="CMS List" >

                <Table
                    antTableProps={{
                        showHeader: true,
                        columns,
                        dataSource: instituteList,
                        pagination: true,
                        bordered: true,
                        rowKey: 'instituteId',

                    }}
                    mobileBreakPoint={768}
                />


            </Card>
        </>
    )
}
