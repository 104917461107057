import { post, get, del} from "../http";


export const galleryInfoSaveUrl = (payload) => post("/gallery/info/save", payload);
export const galleryInfoListUrl = () => get("/gallery/info/list");
export const galleryInfoUpdateUrl = (payload) => post("/gallery/info/update", payload);
export const deleteGalleryInfoUrl = (payload) => del(`/gallery/info/delete?galleryId=${payload}`);


export const noticeInfoSaveUrl = (payload) => post("/notice/info/save", payload);
export const noticeInfoListUrl = () => get("/notice/info/list");
export const noticeInfoUpdateUrl = (payload) => post("/notice/info/update", payload);
export const deleteNoticeInfoUrl = (payload) => del(`/notice/info/delete?noticeId=${payload}`);


export const memberInfoSaveUrl = (payload) => post("/member/info/save", payload);
export const memberInfoListUrl = () => get("/member/info/list");
export const memberInfoUpdateUrl = (payload) => post("/member/info/update", payload);
export const deleteMemberInfoUrl = (payload) => del(`/member/info/delete?memberId=${payload}`);

export const instiuteInfoListUrl = () => get("/cms/info");
export const instiuteInfoListUpdate = (payload) => post("/cms/update", payload);

export const speechInfoSaveUrl = (payload) => post("/speech/info/save", payload);
export const speechInfoListUrl = () => get("/speech/info/list");
export const speechInfoUpdateUrl = (payload) => post("/speech/info/update", payload);
export const deleteSpeechInfoUrl = (payload) => del(`/speech/info/delete?speechId=${payload}`);

export const eventInfoSaveUrl = (payload) => post("/event/info/save", payload);
export const eventInfoListUrl = () => get("/event/info/list");
export const eventInfoUpdateUrl = (payload) => post("/event/info/update", payload);
export const deleteEventInfoUrl = (payload) => del(`/event/info/delete?eventId=${payload}`);

export const importantLinksInfoSaveUrl = (payload) => post("/important/link/save", payload);
export const importantLinksInfoListUrl = () => get("/important/link/list");
export const importantLinksInfoUpdateUrl = (payload) => post("/important/link/update", payload);
export const importantLinksInfoDeleteUrl = (payload) => del(`/important/link/delete?linkId=${payload}`);

export const aboutUsInfoSaveUrl = (payload) => post("/aboutus/info/save", payload);
export const aboutUsInfoListUrl = () => get("/aboutus/info/list");
export const aboutUsInfoUpdateUrl = (payload) => post("/aboutus/info/update", payload);
export const aboutUsInfoUpdateDeleteUrl = (payload) => del(`/aboutus/info/delete?aboutUsId=${payload}`);

export const statisticInfoSaveUrl = (payload) => post("/statistic/info/save", payload);
export const statisticInfoListUrl = () => get("/statistic/info/list");
export const statisticInfoUpdateUrl = (payload) => post("/statistic/info/update", payload);
export const statisticInfoDeleteUrl = (payload) => del(`/statistic/info/delete?id=${payload}`);

export const resultInfoSaveUrl = (payload) => post("/result/info/save", payload);
export const resultInfoListUrl = () => get("/result/info/list");
export const resultInfoUpdateUrl = (payload) => post("/result/info/update", payload);
export const resultInfoDeleteUrl = (payload) => del(`/result/info/delete?resultId=${payload}`);

export const topStudentSaveUrl = (payload) => post("/top/student/save", payload);
export const topStudentListUrl = () => get("/top/student/list");
export const topStudentUpdateUrl = (payload) => post("/top/student/update", payload);
export const topStudentDeleteUrl = (payload) => del(`/top/student/delete?studentId=${payload}`);

export const examRoutineSaveUrl = (payload) => post("/exam/routine/save", payload);
export const examRoutineListUrl = () => get("/exam/routine/list");
export const examRoutineUpdateUrl = (payload) => post("/exam/routine/update", payload);
export const examRoutineDeleteUrl = (payload) => del(`/exam/routine/delete?examRoutineId=${payload}`);

export const syllabusInfoSaveUrl = (payload) => post("/syllabus/save", payload);
export const syllabusInfoListUrl = () => get("/syllabus/list");
export const syllabusInfoUpdateUrl = (payload) => post("/syllabus/update", payload);
export const syllabusInfoDeleteUrl = (payload) => del(`/syllabus/delete?syllabusId=${payload}`);

export const classRoutineInfoSaveUrl = (payload) => post("/class/routine/save", payload);
export const classRoutineInfoListUrl = () => get("/class/routine/list");
export const classRoutineInfoUpdateUrl = (payload) => post("/class/routine/update", payload);
export const classRoutineInfoDeleteUrl = (payload) => del(`/class/routine/delete?classRoutineId=${payload}`);


