import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectMember } from '../../select/SelectMember';


const { Option } = Select;

export default function SpeechInfo() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [speechId, setSpeechId] = useState<any>();
    const fetchMemberInfoListForSpeech = useStoreActions((state) => state.pages.fetchMemberInfoListForSpeech);
    const storeSpeechInfo = useStoreActions((state) => state.pages.storeSpeechInfo);
    const fetchSpeechInfoList = useStoreActions((state) => state.pages.fetchSpeechInfoList);
    const speechInfoList = useStoreState((state) => state.pages.speechInfoList);
    const updateSpeechInfo = useStoreActions((state) => state.pages.updateSpeechInfo);
    const deleteSpeechInfo = useStoreActions((state) => state.pages.deleteSpeechInfo);
     
    
    const updateSpeechInfoSubmit = (value) => {
        let payload = {
            "details": value?.detailsUpdate,
            "serial": value?.serialUpdate,
            "speechId": speechId,
            "speechStatus": value.statusUpdate == "enable" ? 1 : 0,
            "title": value?.titleUpdate
        }
        updateSpeechInfo(payload);
        updateForm.resetFields();
        setIsModalVisible(false);
    }
    
    useEffect(() => {
        fetchMemberInfoListForSpeech();
        fetchSpeechInfoList();
    },[]);

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const deleteSpeechInfoSubmit = (value) => {
        deleteSpeechInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Title', dataIndex: 'title', key: 'title', showOnResponse: true, showOnDesktop: true  },
        { title: 'Member', dataIndex: 'memberName', key: 'memberName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Details', dataIndex: 'details', key: 'details', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'speechStatusString', key: 'speechStatusString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setSpeechId(record?.speechId);
                            updateForm.setFieldsValue({
                                serialUpdate: record?.serial,
                                titleUpdate: record?.title,
                                detailsUpdate: record?.details,
                                statusUpdate: record?.speechStatusString,
                            })
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSpeechInfoSubmit(record?.speechId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const speechInfoSubmit = (value) => {
        let postData = {
            "details": value?.details,
            "memberId": value?.member,
            "serial": value?.serial,
            "speechStatus": value?.status == 'enable' ? 1 : 0,
            "title": value?.title
        }
        storeSpeechInfo(postData);
        form.resetFields();
    }

    console.log('speechInfoList', speechInfoList);
    

    return (
        <>
            <Card title="Speech Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form layout="vertical" id='create-class' form={form} onFinish={speechInfoSubmit}>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="member"
                                        label="Member"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select member" },
                                        ]} 
                                    >
                                        <SelectMember />
                                        
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="details"
                                        label="Details"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write details" },
                                        ]} 
                                    >
                                        <Input.TextArea placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select status" },
                                        ]} 
                                    >
                                        <Select placeholder="Select status" >
                                            <Option key={0} value="enable">Enable</Option>
                                            <Option key={1} value="disable">Disable</Option>
                                        </Select> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right" }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: speechInfoList,
                                        filterData: speechInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Speech Info Update"
                visible={isModalVisible}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                maskClosable={false}
                okButtonProps={{ form: 'speechInfo', htmlType: 'submit' }}
            >
                <Form layout="vertical" id='speechInfo' form={updateForm} onFinish={updateSpeechInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="titleUpdate"
                                label="Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="detailsUpdate"
                                label="Details"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write details" },
                                ]} 
                            >
                                <Input.TextArea placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="statusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" >
                                    <Option key={0} value="enable">Enable</Option>
                                    <Option key={1} value="disable">Disable</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}