import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Anchor, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
const { Option } = Select;
const { Link } = Anchor;

export default function SyllabusInfo() {
    const { Title, Paragraph, Text } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const [fileTypeChange, setFileTypeChange] = useState<any>();
    const [fileTypeChangeUpdate, setFileTypeChangeUpdate] = useState<any>();
    const storeSyllabusInfo = useStoreActions((state) => state.pages.storeSyllabusInfo);
    const fetchSyllabusInfoList = useStoreActions((state) =>  state.pages.fetchSyllabusInfoList);
    const syllabusInfoList = useStoreState((state) => state.pages.syllabusInfoList);
    const updateSyllabusInfo = useStoreActions((state) => state.pages.updateSyllabusInfo);
    const deleteSyllabusInfo = useStoreActions((state) => state.pages.deleteSyllabusInfo);

    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");
    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [syllabusId, setSyllabusId] = useState<any>("");

    console.log('syllabusInfoList', syllabusInfoList);
    

    const updatesyllabusInfoSubmit = (value) => {
        let postDate = {
            "className": value?.classNameUpdate,
            "fileContent": fileContentUpdate,
            "fileSave": fileContentUpdate == '' ? false : true,
            "fileType": fileTypeUpdate,
            "headerText": value?.headerTextUpdate,
            "serial": value?.serialUpdate,
            "showStatus": value.statusUpdate == "Inactive" ? 0 : 1,
            "syllabusId": syllabusId,
            "year": moment(value?.yearUpdate).format(dateFormat)
        }
        updateSyllabusInfo(postDate);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        updateForm.resetFields();
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);

    }

    useEffect(() => {
        fetchSyllabusInfoList();
    },[]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deletesyllabusInfoSubmit = (value) => {
        deleteSyllabusInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Header Text', dataIndex: 'headerText', key: 'headerText', showOnResponse: true, showOnDesktop: true  },
        { title: 'Files', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Anchor>
                    <Link href={record?.filePath} target="_blank" title={record?.fileName} />
                </Anchor>
            </>
        )},
        { title: 'Year', dataIndex: 'year', key: 'year', showOnResponse: true, showOnDesktop: true },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setSyllabusId(record.syllabusId);
                            updateForm.setFieldsValue({
                                serialUpdate : record.serial,
                                classNameUpdate : record.className,
                                headerTextUpdate : record.headerText,
                                subTitleUpdate : record.subTitle,
                                yearUpdate: moment(JSON.stringify(record?.year)),
                                showStatus: record.showStatus == 1 ? "Active" : "Inactive"
                            });
                            let fileTypeName = record?.fileName;
                            let fileTypeNameGen = fileTypeName.split('.')[1] ;
                            fileTypeNameGen == "pdf" || fileTypeNameGen == "docx" ? setFileTypeChangeUpdate("File") : setFileTypeChangeUpdate("Image");
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"  
                        onConfirm={() => deletesyllabusInfoSubmit(record?.syllabusId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const dateFormat = 'YYYY';

    const syllabusInfoSubmit = (value) => {
        let postData:any = {
            "className": value?.className,
            "fileContent": fileContent,
            "fileSave": true,
            "fileType": fileType,
            "headerText": value?.headerText,
            "serial": value?.serial,
            "year": moment(value?.issueDateUpdate).format(dateFormat),
        }
        storeSyllabusInfo(postData);
        form.resetFields();
        setfileContent("");
        setfileType("");
        setFileList([]);
    }   

    const onChangeFileType = (value) =>{
        setFileTypeChange(value);
        setfileContent("");
        setfileType("");
        setFileList([]);
    }

    const onChangeFileTypeUpdate = (value) =>{
        setFileTypeChangeUpdate(value);
    }

    return (
        <>
            <Card title="Syllabus Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={syllabusInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="className"
                                        label="Class Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write class name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write class name" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="headerText"
                                        label="Header Text"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write header text" },
                                        ]} 
                                    >
                                        <Input placeholder="Write header text" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="year"
                                        label="Year"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]} 
                                    >
                                        <DatePicker format={dateFormat} placeholder="select year" style={{ width: "100%" }} picker="year"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="fileType"
                                        label="Upload File Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please Select Upload File Type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select File Type" onChange={(e) => onChangeFileType(e)}>
                                            <Option key={0} value="File">File</Option>
                                            <Option key={1} value="Image">Image</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {fileTypeChange == "Image" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                }
                                {fileTypeChange == "File" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="File:"
                                            className="title-Text"
                                        >
                                            <Upload
                                                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture"
                                                accept="application/pdf,application/vnd.ms-word"
                                                fileList={fileList}
                                                onChange={onChangeFile}
                                                onPreview={onPreview}
                                            >
                                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right", marginTop: 0 }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: syllabusInfoList,
                                    filterData: syllabusInfoList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "id",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Syllabus Info Update"
                visible={isModalVisible}
                // onOk={updateSyllabusInfo}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ form: 'syllabusNoticeUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='syllabusNoticeUpdate' form={updateForm} onFinish={updatesyllabusInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="classNameUpdate"
                                label="Class Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write class name" },
                                ]} 
                            >
                                <Input placeholder="Write class name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="headerTextUpdate"
                                label="Header Text"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write header text" },
                                ]} 
                            >
                                <Input placeholder="Write header text" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="yearUpdate"
                                label="Year"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]} 
                            >
                                <DatePicker format={dateFormat} placeholder="select year" style={{ width: "100%" }} picker="year" />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="showStatus"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" style={{ width: "100%" }}>
                                    <Option key={0} value="Active">Active</Option>
                                    <Option key={1} value="Inactive">Inactive</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="fileType_update"
                                label="Upload File Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Select placeholder="Select File Type" onChange={(e) => onChangeFileTypeUpdate(e)} defaultValue={fileTypeChangeUpdate}>
                                    <Option key={0} value="File">File</Option>
                                    <Option key={1} value="Image">Image</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {fileTypeChangeUpdate == "Image" &&
                            <Col>
                                <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                    <Form.Item
                                        name="user_image_update"
                                        label="Photo:"
                                        className="title-Text"
                                    >
                                        <ImgCrop rotate>
                                            <Upload
                                                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture"
                                                accept="image/png, image/jpeg"
                                                fileList={fileListUpdate}
                                                onChange={onChangeFileUpdate}
                                                onPreview={onPreviewUpdate}
                                            >
                                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                </Spin>
                            </Col>
                        }
                        {fileTypeChangeUpdate == "File" &&
                            <Col>
                                <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                    <Form.Item
                                        name="user_image_update"
                                        label="File:"
                                        className="title-Text"
                                    >
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="application/pdf,application/vnd.ms-word"
                                            fileList={fileListUpdate}
                                            onChange={onChangeFileUpdate}
                                            onPreview={onPreviewUpdate}
                                        >
                                            {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    </Form.Item>
                                </Spin>
                            </Col>
                        }
                    </Row>
                </Form>
            </Modal>

        </>
    )
}