export const ROUTES = {
  DEFAULT: "/",
  INSTITUTE_INFO: "/institute-info",
  GALLERY_INFO: "/gallery-info",
  ABOUTUS_INFO: "/aboutUs-info",
  NOTICE_INFO: "/notice-info",
  EVENT_INFO: "/event-info",
  IMPORTANT_LINK_INFO: "/important-link-info",
  MEMBER_INFO: "/member-info",
  SPEECH_INFO: "/speech-info",
  STATISTIC_INFO: "/statistic-info",
  RESULT_INFO: "/result-info",
  TOP_STUDENT_INFO: "/top-student-info",
  EXAM_ROUTINE_INFO: "/exam-routine-info",
  SYLLABUS_INFO: "/syllabus",
  CLASS_ROUTINE: "/class-routine",
  SAMPLE_PAGE: "sample-page",
  INSTITUTE_LIST: "cms-list-2022",
};
