import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectMember } from '../../select/SelectMember';


const { Option } = Select;

export default function Statistic() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [statisticId, setStatisticId] = useState<any>();
    const fetchStatisticInfoList = useStoreActions((state) => state.pages.fetchStatisticInfoList);
    const storeStatisticInfo = useStoreActions((state) => state.pages.storeStatisticInfo);
    const statisticInfoList = useStoreState((state) => state.pages.statisticInfoList);
    const updateStatisticInfo = useStoreActions((state) => state.pages.updateStatisticInfo);
    const deleteStatisticInfo = useStoreActions((state) => state.pages.deleteStatisticInfo);
     
    
    const updateStatisticInfoInfoSubmit = (value) => {
        let payload = {
            "id": statisticId,
            "name": value?.nameUpdate,
            "quantity": value?.quantityUpdate,
            "serial": value?.serialUpdate
          }
        updateStatisticInfo(payload);
        updateForm.resetFields();
        setIsModalVisible(false);
    }
    
    useEffect(() => {
        fetchStatisticInfoList();
    },[]);

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const deletestatisticInfoSubmit = (value) => {
        deleteStatisticInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Name', dataIndex: 'name', key: 'name', showOnResponse: true, showOnDesktop: true  },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setStatisticId(record?.id);
                            updateForm.setFieldsValue({
                                serialUpdate: record?.serial,
                                nameUpdate: record?.name,
                                quantityUpdate: record?.quantity,
                            })
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletestatisticInfoSubmit(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const statisticInfoSubmit = (value) => {
        let postData = {
            "name": value?.statisticName,
            "quantity": value?.quantity,
            "serial": value?.serial,
        }
        storeStatisticInfo(postData);
        form.resetFields();
    }

    return (
        <>
            <Card title="Statistic Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form layout="vertical" id='create-class' form={form} onFinish={statisticInfoSubmit}>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="statisticName"
                                        label="Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write statistic" },
                                        ]} 
                                    >
                                        <Input placeholder="Write statistic" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="quantity"
                                        label="Quantity"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write quantity" },
                                        ]} 
                                    >
                                        <InputNumber placeholder="Write quantity" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right" }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: statisticInfoList,
                                        filterData: statisticInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Statistic Info Update"
                visible={isModalVisible}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                maskClosable={false}
                okButtonProps={{ form: 'statisticInfo', htmlType: 'submit' }}
            >
                <Form layout="vertical" id='statisticInfo' form={updateForm} onFinish={updateStatisticInfoInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="nameUpdate"
                                label="Statistic"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write name" },
                                ]} 
                            >
                                <Input placeholder="Write name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="quantityUpdate"
                                label="Quantity"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write quantity" },
                                ]} 
                            >
                                <InputNumber placeholder="Write quantity" /> 
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}