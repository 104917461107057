import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Anchor, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
const { Option } = Select;

export default function MemberInfo() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const [fileType, setfileType] = useState<any>("");
    const dateFormat = 'YYYY-MM-DD';
    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");
    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [memberId, setMemberId] = useState<any>(""); 

    const storememberInfo = useStoreActions((state) =>state.pages.storememberInfo);
    const fetchMemberInfoList = useStoreActions((state) => state.pages.fetchMemberInfoList);
    const memberInfoList = useStoreState((state) => state.pages.memberInfoList);
    const updateMemberInfo = useStoreActions((state) => state.pages.updateMemberInfo);
    const deleteMemberInfo = useStoreActions((state) =>  state.pages.deleteMemberInfo);
    const updateMemberInfoSubmit = (value) => {
        let postData = {
            address: value?.addressUpdate,
            age: value?.ageUpdate,
            designation: value?.designationUpdate,
            details: value?.detailsUpdate,
            email: value?.emailAddressUpdate,
            fileContent: fileContentUpdate,
            fileSave: fileContentUpdate == '' ? false : true,
            fileType: fileTypeUpdate,
            gender: value?.genderUpdate,
            hobby: value?.hobbyUpdate,
            joiningDate: moment(value?.joinDateUpdate).format("YYYY-MM-DD"),
            linkFacebook: value?.facebookUpdate,
            linkLinkedin: value?.linkedinUpdate,
            linkTwitter: value?.twitterUpdate,
            memberId: memberId,
            memberName: value?.nameUpdate,
            memberStatus: value.statusUpdate == "Deactive" ? 0 : 1,
            phone: value?.phoneUpdate,
            serial: value?.serialUpdate,
            type: value?.typeUpdate,
        }
        updateMemberInfo(postData);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);
    }

    

    const handleCancel = () => {
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);
    }

    useEffect(() => {
        fetchMemberInfoList();
    },[]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deleteMemberInfoSubmit = (value) => {
        deleteMemberInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Photo', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
            render: (text: any, record: any, index) => (
                <>
                    <img
                        src={record?.filePath}
                        height="80"
                    />
                </>
        )},
        { title: 'Name', dataIndex: 'memberName', key: 'memberName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Email', dataIndex: 'email', key: 'email', showOnResponse: true, showOnDesktop: true  },
        { title: 'Phone', dataIndex:'phone', key: 'phone', showOnResponse: true, showOnDesktop: true  },
        { title: 'Gender', dataIndex:'gender', key: 'gender', showOnResponse: true, showOnDesktop: true  },
        { title: 'Type', dataIndex:'type', key: 'type', showOnResponse: true, showOnDesktop: true  },
        { title: 'Age', dataIndex:'age', key: 'age', showOnResponse: true, showOnDesktop: true  },
        { title: 'Address', dataIndex:'address', key: 'address', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'memberStatusStr', key: 'memberStatusStr', showOnResponse: true, showOnDesktop: true  },
        { title: 'Social', dataIndex: 'linkFacebook', key: 'linkFacebook', showOnResponse: true, showOnDesktop: true,render: (text: any, record: any, index) => (
            <>
                <a href={record.linkFacebook} target="_blank" title="Facebook">Facebook</a><br />
                <a href={record.linkTwitter} target="_blank" title="Twitter">Twitter</a><br />
                <a href={record.linkLinkedin} target="_blank" title="Linkedin">Linkedin</a>
            </>
        )},
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setMemberId(record?.memberId);
                            updateForm.setFieldsValue({
                                serialUpdate : record?.serial,
                                typeUpdate : record?.type,
                                nameUpdate : record?.memberName,
                                ageUpdate : record?.age,
                                genderUpdate : record?.gender,
                                hobbyUpdate : record?.hobby,
                                designationUpdate : record?.designation,
                                phoneUpdate : record?.phone,
                                emailAddressUpdate : record?.email,
                                addressUpdate : record?.address,
                                detailsUpdate : record?.details,
                                joinDateUpdate : moment(record?.joiningDate),
                                statusUpdate : record?.memberStatusStr,
                                facebookUpdate : record?.linkFacebook,
                                twitterUpdate : record?.linkTwitter,
                                linkedinUpdate : record?.linkLinkedin
                            })
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteMemberInfoSubmit(record?.memberId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const memberInfoSubmit = (value) => {
        
        let postdata:any = {
            address: value?.address,
            age: value?.age,
            designation: value?.designation,
            details: value?.details,
            email: value?.emailAddress,
            fileContent: fileContent,
            fileSave: true,
            fileType: fileType,
            gender: value?.gender,
            hobby: value?.hobby,
            joiningDate: moment(value?.joinDate).format(dateFormat),
            linkFacebook: value?.facebook,
            linkLinkedin: value?.linkedin,
            linkTwitter: value?.twitter,
            memberName: value?.name,
            memberStatus: value?.status == 'enable' ? 1 : 0,
            phone: value?.phone,
            serial: value?.serial,
            type: value?.type
          }
          storememberInfo(postdata);
          form.resetFields();
          setfileContent("");
          setfileType("");
          setFileList([]);
    }

    return (
        <>
            <Card title="Member Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form}
                            onFinish={memberInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="type"
                                        label="Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select type">
                                            <Option key={0} value="GOVERNING_BODY">Governing Body</Option>
                                            <Option key={1} value="MEMBER">Member</Option>
                                            <Option key={2} value="TEACHER">Teacher</Option>
                                            <Option key={3} value="STUFF">Stuff</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write name" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="age"
                                        label="Age"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write age" },
                                        ]} 
                                    >
                                        <Input placeholder="Write age" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="hobby"
                                        label="Hobby"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                        <Input placeholder="Write hobby" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="designation"
                                        label="Designation"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write designation" },
                                        ]} 
                                    >
                                        <Input placeholder="Write designation" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="phone"
                                        label="Phone"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write phone" },
                                        ]} 
                                    >
                                        <Input placeholder="Write phone" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="gender"
                                        label="Gender"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select gender" },
                                        ]} 
                                    >
                                        <Select placeholder="Select gender" >
                                            <Option key={0} value="Male">Male</Option>
                                            <Option key={1} value="Female">Female</Option>
                                            <Option key={2} value="Other">Other</Option>
                                        </Select> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="emailAddress"
                                        label="Email Address"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                        <Input placeholder="Write email address" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="address"
                                        label="Address"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write address" },
                                        ]} 
                                    >
                                        <Input placeholder="Write address" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="details"
                                        label="Details"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                         <Input.TextArea placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="joinDate"
                                        label="Join Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select join date" },
                                        ]} 
                                    >
                                        <DatePicker placeholder="select join date" style={{ width: "100%" }} format={dateFormat}/> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select status" },
                                        ]} 
                                    >
                                        <Select placeholder="Select status" >
                                            <Option key={0} value="Active">Active</Option>
                                            <Option key={1} value="Deactive">Deactive</Option>
                                        </Select> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="facebook"
                                        label="Facebook"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                        <Input type="text" placeholder="Write facebook link" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="twitter"
                                        label="Twitter"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                        <Input type="text" placeholder="Write twitter link" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Form.Item
                                        name="linkedin"
                                        label="Linkedin"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                    >
                                        <Input type="text" placeholder="Write facebook linkedin" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Spin tip="Uploading..." spinning={start?true:false}>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                                    <Button type="primary" htmlType='submit'>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: memberInfoList,
                                        filterData: memberInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Member Info Update"
                visible={isModalVisible}
                // onOk={handleOk}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                width="80%"
                okButtonProps={{ form: 'memberUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='memberUpdate' form={updateForm} onFinish={updateMemberInfoSubmit} >
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="typeUpdate"
                                label="Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select type" },
                                ]} 
                            >
                                <Select placeholder="Select type">
                                    <Option key={0} value="GOVERNING_BODY">Governing Body</Option>
                                    <Option key={1} value="MEMBER">Member</Option>
                                    <Option key={2} value="TEACHER">Teacher</Option>
                                    <Option key={3} value="STUFF">Stuff</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="nameUpdate"
                                label="Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write name" },
                                ]} 
                            >
                                <Input placeholder="Write name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="ageUpdate"
                                label="Age"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write age" },
                                ]} 
                            >
                                <Input placeholder="Write age" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="genderUpdate"
                                label="Gender"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select gender" },
                                ]} 
                            >
                                <Select placeholder="Select gender" >
                                    <Option key={0} value="Male">Male</Option>
                                    <Option key={1} value="Female">Female</Option>
                                    <Option key={2} value="Other">Other</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="hobbyUpdate"
                                label="Hobby"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input placeholder="Write hobby" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="designationUpdate"
                                label="Designation"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write designation" },
                                ]} 
                            >
                                <Input placeholder="Write designation" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="phoneUpdate"
                                label="Phone"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write phone" },
                                ]} 
                            >
                                <Input placeholder="Write phone" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="emailAddressUpdate"
                                label="Email Address"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input placeholder="Write email address" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="addressUpdate"
                                label="Address"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write address" },
                                ]} 
                            >
                                <Input placeholder="Write address" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="detailsUpdate"
                                label="Details"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                    <Input.TextArea placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="joinDateUpdate"
                                label="Join Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select join date" },
                                ]} 
                            >
                                <DatePicker placeholder="select join date" style={{ width: "100%" }} format="YYYY-MM-DD"/> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="statusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" >
                                    <Option key={0} value="Active">Active</Option>
                                    <Option key={1} value="Deactive">Deactive</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="facebookUpdate"
                                label="Facebook"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write facebook link" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="twitterUpdate"
                                label="Twitter"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write twitter link" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Form.Item
                                name="linkedinUpdate"
                                label="Linkedin"
                                className="title-Text"
                                style={{ width: "100%" }}
                            >
                                <Input type="text" placeholder="Write facebook linkedin" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:6}}>
                            <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                <Form.Item
                                    name="user_image_update"
                                    label="Photo:"
                                    className="title-Text"
                                >
                                    <ImgCrop rotate>
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListUpdate}
                                            onChange={onChangeFileUpdate}
                                            onPreview={onPreviewUpdate}
                                        >
                                            {fileListUpdate.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}