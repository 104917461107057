import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Anchor, Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
const { Option } = Select;
const { Link } = Anchor;

export default function ExamRoutineInfo() {
    const { Title, Paragraph, Text } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);
    const [fileTypeChange, setFileTypeChange] = useState<any>();
    const [fileTypeChangeUpdate, setFileTypeChangeUpdate] = useState<any>();
    const storeExamRoutineInfo = useStoreActions((state) => state.pages.storeExamRoutineInfo);
    const fetchExamRoutineInfoList = useStoreActions((state) =>  state.pages.fetchExamRoutineInfoList);
    const examRoutineInfoList = useStoreState((state) => state.pages.examRoutineInfoList);
    const updateExamRoutineInfo = useStoreActions((state) => state.pages.updateExamRoutineInfo);
    const deleteExamRoutineInfo = useStoreActions((state) => state.pages.deleteExamRoutineInfo);

    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");
    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [examRoutineId, setExamRoutineId] = useState<any>("");

    const updateExamRoutineInfoSubmit = (value) => {
        let postDate = {
            className: value?.classNameUpdate,
            endDate: moment(value?.endDateUpdate).format(dateFormat),
            examName: value?.examNameUpdate,
            examRoutineId: examRoutineId,
            fileContent: fileContentUpdate,
            fileSave: fileContentUpdate == '' ? false : true,
            fileType: fileTypeUpdate,
            routineHeader: value?.routineHeaderUpdate,
            routineStatus:  value.examRoutineStatusUpdate == "Active" ? 1 : 0,
            serial: value?.serialUpdate,
            startDate: moment(value?.startDateUpdate).format(dateFormat),
        }
        console.log('value', updateExamRoutineInfo(postDate));
        // updateExamRoutineInfo(postDate);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
        setIsModalVisible(false);

    }

    useEffect(() => {
        fetchExamRoutineInfoList();
    },[]);

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deleteExamRoutineInfoSubmit = (value) => {
        deleteExamRoutineInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Routine Header', dataIndex: 'routineHeader', key: 'routineHeader', showOnResponse: true, showOnDesktop: true  },
        { title: 'Exam Name', dataIndex: 'examName', key: 'examName', showOnResponse: true, showOnDesktop: true  },
        { title: 'Class Name', dataIndex: 'className', key: 'className', showOnResponse: true, showOnDesktop: true  },
        { title: 'Files', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Anchor>
                    <Link href={record?.filePath} target="_blank" title={record?.fileName} />
                </Anchor>
            </>
        )},
        { title: 'Date', dataIndex: 'date', key: 'date', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <div style={{ width: "100px" }}>
                <span>{record?.startDate} - {record?.endDate}</span>
            </div>
        )},
        { title: 'Status', dataIndex: 'routineStatusString', key: 'routineStatusString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setExamRoutineId(record.examRoutineId);
                            updateForm.setFieldsValue({
                                classNameUpdate : record?.className,
                                endDateUpdate : moment(record.endDate),
                                examNameUpdate : record?.examName,
                                routineHeaderUpdate : record?.routineHeader,
                                serialUpdate : record.serial,
                                startDateUpdate: moment(record.startDate),
                                examRoutineStatusUpdate:record.routineStatusString
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"  
                        onConfirm={() => deleteExamRoutineInfoSubmit(record?.examRoutineId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const dateFormat = 'YYYY-MM-DD';

    const examRoutineInfoSubmit = (value) => {
        let postData:any = {
            className: value?.className,
            endDate: moment(value?.endDate).format("YYYY-MM-DD"),
            examName: value?.examName,
            fileContent: fileContent,
            fileSave: true,
            fileType: fileType, 
            routineHeader: value?.routineHeader,
            serial: value?.serial,
            startDate: moment(value?.startDate).format("YYYY-MM-DD")
        }
        console.log('postData', postData);
        
        storeExamRoutineInfo(postData);
        form.resetFields();
        setfileContent("");
        setfileType("");
        setFileList([]);
    }   

    const onChangeFileType = (value) =>{
        setFileTypeChange(value);
        setfileContent("");
        setfileType("");
        setFileList([]);
    }

    const onChangeFileTypeUpdate = (value) =>{
        setFileTypeChangeUpdate(value);
    }

    return (
        <>
            <Card title="Exam Routine Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={examRoutineInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="routineHeader"
                                        label="Routine Header"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write routine header" },
                                        ]} 
                                    >
                                        <Input placeholder="Write routine header" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="className"
                                        label="Class Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write class name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="examName"
                                        label="Exam Name"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write exam name" },
                                        ]} 
                                    >
                                        <Input placeholder="Write exam name" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="startDate"
                                        label="Start Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select start date" },
                                        ]} 
                                    >
                                        <DatePicker format={dateFormat} placeholder="select start date" style={{ width: "100%" }}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="endDate"
                                        label="End Date"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select end date" },
                                        ]} 
                                    >
                                        <DatePicker format={dateFormat} placeholder="select end date" style={{ width: "100%" }}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="fileType"
                                        label="Upload File Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please Select Upload File Type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select File Type" onChange={(e) => onChangeFileType(e)}>
                                            <Option key={0} value="File">File</Option>
                                            <Option key={1} value="Image">Image</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {fileTypeChange == "Image" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                            <ImgCrop rotate>
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                            </ImgCrop>
                                        </Form.Item>
                                    </Col>
                                }
                                {fileTypeChange == "File" && 
                                    <Col>
                                        <Form.Item
                                            name="user_image"
                                            label="File:"
                                            className="title-Text"
                                        >
                                            <Upload
                                                //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture"
                                                accept="application/pdf,application/vnd.ms-word"
                                                fileList={fileList}
                                                onChange={onChangeFile}
                                                onPreview={onPreview}
                                            >
                                                {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right", marginTop: 0 }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: examRoutineInfoList,
                                        filterData: examRoutineInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Notice Info Update"
                visible={isModalVisible}
                // onOk={updateExamRoutineInfo}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ form: 'noticeUpdate', htmlType: 'submit' }}
                maskClosable={false}
                width="50%"
            >
                <Form layout="vertical" id='noticeUpdate' form={updateForm} onFinish={updateExamRoutineInfoSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="routineHeaderUpdate"
                                label="Routine Header"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write routine header" },
                                ]} 
                            >
                                <Input placeholder="Write routine header" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="classNameUpdate"
                                label="Class Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write class name" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="examNameUpdate"
                                label="Exam Name"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write exam name" },
                                ]} 
                            >
                                <Input placeholder="Write exam name" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="startDateUpdate"
                                label="Start Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select start date" },
                                ]} 
                            >
                                <DatePicker format={dateFormat} placeholder="select start date" style={{ width: "100%" }}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="endDateUpdate"
                                label="End Date"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select end date" },
                                ]} 
                            >
                                <DatePicker format={dateFormat} placeholder="select end date" style={{ width: "100%" }}/>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Form.Item
                                name="examRoutineStatusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" >
                                    <Option key={0} value="Active">Active</Option>
                                    <Option key={1} value="Deactive">Deactive</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                            <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                <Form.Item
                                    name="user_imageUpdate"
                                    label="File:"
                                    className="title-Text"
                                >
                                    <Upload
                                        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        accept="application/pdf,application/vnd.ms-word"
                                        fileList={fileListUpdate}
                                        onChange={onChangeFileUpdate}
                                        onPreview={onPreviewUpdate}
                                    >
                                        {fileListUpdate.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                    </Upload>
                                </Form.Item>
                            </Spin>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}