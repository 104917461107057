import { Layout, Menu, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "./store/hooks/easyPeasy";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { ROUTES } from "./contents/routes";
import TopNavBar from "./topNavbar";
import { Sidebar } from "./layouts/sidebar/Sidebar";
import { Link } from "react-router-dom";

import Dashboard from './container/pages/dashboard/Dashboard.page';

import logo from "../assets/images/sdl.png";

import { Footer } from "antd/lib/layout/layout";
import InstituteInfo from "./container/pages/cmsPages/InstituteInfo.page";
import GalleryInfo from "./container/pages/cmsPages/GalleryInfo.page";
import AboutUsInInfo from "./container/pages/cmsPages/AboutUsInInfo.page";
import NoticeInfo from "./container/pages/cmsPages/NoticeInfo.page";
import EventInfo from "./container/pages/cmsPages/EventInfo.page";
import ImportantLinksInfo from "./container/pages/cmsPages/ImportantLinksInfo.page";
import MemberInfo from "./container/pages/cmsPages/MemberInfo.page";
import SpeechInfo from "./container/pages/cmsPages/SpeechInfo.page";
import Statistic from "./container/pages/cmsPages/Statistic.page";
import ResultInfo from "./container/pages/cmsPages/ResultInfo.page";
import TopStudentInfo from "./container/pages/cmsPages/TopStudentInfo.page copy";
import ExamRoutineInfo from "./container/pages/cmsPages/ExamRoutineInfo.page";
import SyllabusInfo from "./container/pages/cmsPages/SyllabusInfo.page";
import ClassRoutineInfo from "./container/pages/cmsPages/ClassRoutineInfo.page";
import Profile from './container/pages/signup/Profile.page';
import InstituteList from "./container/pages/CustomerSupport/InstituteList";

const { Header, Content, Sider } = Layout;
// Application
export default function Container() {
  const user = useStoreState((state) => state.auth.user);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }
  const onCollapse = (collapsed) => {
    //console.log("hello")
    //console.log(collapsed);
    setCollapsed(collapsed);
  };
 


  // useEffect(() => {
  //   localStorage.setItem('insinfo', JSON.stringify(instituteBasicData));
  // },[instituteBasicData])

  const d = new Date();
  let year = d.getFullYear();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="mySider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        trigger={null}
        collapsedWidth={isMobile ? 0 : 80}
        width="230"
        style={{
          height: "100vh",
          overflow: "auto",
          position: isMobile ? "fixed" : "sticky",
          left: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <div className="logo">
          {!collapsed && (
            <>
              <a href="#"><img src={logo} style={{height:58, width:200}} /></a>
            </>
          )}
          {collapsed && (
            <div style={{ marginLeft: 15 }}>
              <AppstoreOutlined />
            </div>
          )}
          {/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
        </div>
        {user && (
          <div className="sidebar-wrapper" id="style-4">
            {" "}
            <Sidebar />
          </div>
        )}
      </Sider>
      <Layout className="site-layout">
      <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1}} id="topFixedBar">
        {/* <Header className="site-layout-background"> */}
          <TopNavBar value={{ collapsed: collapsed, toggle: toggle }} />
        </Header>
        <Content className="p-15" style={{ marginTop: "64px" }}>
        {/* <Content className="p-15"> */}
          <Switch>
            <Route exact path="/" component={Dashboard} /> 
            <Route exact path={ROUTES.INSTITUTE_INFO} component={InstituteInfo} />
            <Route exact path={ROUTES.GALLERY_INFO} component={GalleryInfo} />
            <Route exact path={ROUTES.ABOUTUS_INFO} component={AboutUsInInfo} />
            <Route exact path={ROUTES.NOTICE_INFO} component={NoticeInfo} />
            <Route exact path={ROUTES.EVENT_INFO} component={EventInfo} /> 
            <Route exact path={ROUTES.IMPORTANT_LINK_INFO} component={ImportantLinksInfo} />
            <Route exact path={ROUTES.MEMBER_INFO} component={MemberInfo} />
            <Route exact path={ROUTES.SPEECH_INFO} component={SpeechInfo} />
            <Route exact path={ROUTES.STATISTIC_INFO} component={Statistic} />
            <Route exact path={ROUTES.RESULT_INFO} component={ResultInfo} />
            <Route exact path={ROUTES.TOP_STUDENT_INFO} component={TopStudentInfo} />
            <Route exact path={ROUTES.EXAM_ROUTINE_INFO} component={ExamRoutineInfo} />
            <Route exact path={ROUTES.SYLLABUS_INFO} component={SyllabusInfo} />
            <Route exact path={ROUTES.CLASS_ROUTINE} component={ClassRoutineInfo} />
            <Route exact path={'/' + ROUTES.INSTITUTE_LIST} component={InstituteList} />
            <Route exact path="/change-password" component={Profile} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', padding:"15px" }}>
          &copy; Copyright {year} <Link to="https://shebadigital.com/" style={{ color: "#00CA88" }}>Sheba Digital Limited</Link> | Part of Sheba Group.
        </Footer>
      </Layout>
    </Layout>
  );
}
