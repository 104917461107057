import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';

const { Option } = Select;

export default function GalleryInfo() {
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const [fileSave, setfileSave] = useState<boolean>(false);
    const [fileName, setfileName] = useState<any>("");
    const [fileContent, setfileContent] = useState<any>("");
    const [fileType, setfileType] = useState<any>("");
    const [disBtn, setdisBtn] = useState<boolean>(false);
    const storeGallaryInfo = useStoreActions((state) => state.pages.storeGallaryInfo);
    const fetchGalleryInfoList = useStoreActions((state) => state.pages.fetchGalleryInfoList);
    const galleryInfoList = useStoreState((state) => state.pages.galleryInfoList);
    const updateGallaryInfo = useStoreActions((state) => state.pages.updateGallaryInfo);
    const deleteGallaryInfo = useStoreActions((state) => state.pages.deleteGallaryInfo);
    const [visible, setVisible] = useState(false);
    const [filePathForUpdate, setFilePathForUpdate] = useState<any>("");


    const [fileListUpdate, setFileListUpdate] = useState<any>([]);
    const [fileSaveUpdate, setfileSaveUpdate] = useState<boolean>(false);
    const [fileNameUpdate, setfileNameUpdate] = useState<any>("");
    const [fileContentUpdate, setfileContentUpdate] = useState<any>("");
    const [fileTypeUpdate, setfileTypeUpdate] = useState<any>("");
    const [galleryIdUpdate, setGalleryIdUpdate] = useState<any>("");
    const [startUpdate, setStartUpdate] = useState<boolean>(false);
    const [start, setStart] = useState<boolean>(false);

    const onUpDateForm = (value) => {
        let postdata:any = {
            "details": value.detailsUpdate,
            "galleryId": galleryIdUpdate,
            "imageContent": fileContentUpdate,
            "imageSave": fileContentUpdate == '' ? false : true,
            "imageType": fileTypeUpdate,
            "serial": value?.serialUpdate,
            "subTitle": value?.subTitleUpdate,
            "title": value?.titleUpdate,
            "type": value?.typeUpdate
        };
        console.log(postdata)
        updateGallaryInfo(postdata);
        setIsModalVisible(false);
        updateForm.resetFields();
        setIsModalVisible(false);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([])
    }

    useEffect(() => {
        fetchGalleryInfoList();
    },[]);

    const handleCancel = () => {
        setIsModalVisible(false);
        setfileContentUpdate("");
        setfileTypeUpdate("");
        setFileListUpdate([]);
        setFileList([]);
    }

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFile = ({ fileList: newFileList }) => {
        if (newFileList.length == 1) {
            setStart(true);
            newFileList[0].status = "done"
            setfileSave(true);
            setfileName(newFileList[0]?.name);
            setfileType(newFileList[0]?.name.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(newFileList[0]?.originFileObj);
            setTimeout(() => {
                setfileContent(reader.result.split(',')[1]);
                setStart(false);
            }, 2000);

        }
        setFileList(newFileList);
    };

    const onPreviewUpdate = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                //console.log(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onChangeFileUpdate = ({ fileList: fileListUpdate }) => {
        if (fileListUpdate?.length == 1) {
            setStartUpdate(true);
            fileListUpdate[0].status = "done"
            setfileSaveUpdate(true);
            setfileNameUpdate(fileListUpdate[0]?.name);
            setfileTypeUpdate(fileListUpdate[0]?.name?.split('.')[1]);
            const reader: any = new FileReader();
            reader.readAsDataURL(fileListUpdate[0]?.originFileObj);
            setTimeout(() => {
                setfileContentUpdate(reader.result.split(',')[1]);
                setStartUpdate(false);
            }, 2000);
       
        }
        setFileListUpdate(fileListUpdate);
    };

    const deleteGalleryInfo = (value) => {
        deleteGallaryInfo(value);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Photo', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <img
                    src={record?.filePath}
                    height="120"
                />
            </>
        )},
        { title: 'Title', dataIndex: 'title', key: 'title', showOnResponse: true, showOnDesktop: true  },
        { title: 'Sub-title', dataIndex: 'subTitle1', key: 'subTitle1', showOnResponse: true, showOnDesktop: true  },
        { title: 'Details', dataIndex: 'details', key: 'details', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setFilePathForUpdate(record.filePath);
                            setGalleryIdUpdate(record.galleryId);
                            console.log(fileContentUpdate)
                            updateForm.setFieldsValue({
                                serialUpdate : record.serial,
                                typeUpdate : record.type,
                                titleUpdate : record.title,
                                subTitleUpdate : record.subTitle1,
                                detailsUpdate : record.details,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteGalleryInfo(record?.galleryId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const galleryInfoSubmit = (value) => {
        let postData:any = {
            details : value.details,
            imageContent : fileContent,
            imageSave : true,
            imageType : fileType,
            serial : value.serial,
            subTitle : value.subTitle,
            title: value.title,
            type : value.type
        }
        storeGallaryInfo(postData);
        setfileContent("");
        setfileType("");
        setFileList([]);
        setFileList([]);
        form.resetFields();        
    }

    return (
        <>
            <Card title="Gallery Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={galleryInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="type"
                                        label="Type"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select type" },
                                        ]} 
                                    >
                                        <Select placeholder="Select type">
                                            <Option key={0} value="HOME_SLIDER">Home slider</Option>
                                            <Option key={1} value="GALLERY_PHOTO">Gallery photo</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="subTitle"
                                        label="Sub Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write sub title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write sub title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="details"
                                        label="Details"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write details" },
                                        ]} 
                                    >
                                        <Input.TextArea placeholder="Write details" /> 
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Spin tip="Uploading..." spinning={start?true:false}>
                                        <Form.Item
                                            name="user_image"
                                            label="Photo:"
                                            className="title-Text"
                                        >
                                                <Upload
                                                    //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture"
                                                    accept="image/png, image/jpeg"
                                                    fileList={fileList}
                                                    onChange={onChangeFile}
                                                    // onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                                </Upload>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right", marginTop: 0 }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: galleryInfoList,
                                        filterData: galleryInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Gallery Info Update"
                visible={isModalVisible}
                // onOk={onUpDateForm}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                width="50%"
                okButtonProps={{ form: 'galleryUpdate', htmlType: 'submit' }}
                maskClosable={false}
            >
                <Form layout="vertical" id='galleryUpdate' form={updateForm} onFinish={onUpDateForm}>
                <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="typeUpdate"
                                label="Type"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select type" },
                                ]} 
                            >
                                <Select placeholder="Select type">
                                    <Option key={0} value="HOME_SLIDER">Home slider</Option>
                                    <Option key={1} value="GALLERY_PHOTO">Gallery photo</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="titleUpdate"
                                label="Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="subTitleUpdate"
                                label="Sub Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write sub title" },
                                ]} 
                            >
                                <Input placeholder="Write sub title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="detailsUpdate"
                                label="Details"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write details" },
                                ]} 
                            >
                                <Input.TextArea placeholder="Write details" /> 
                            </Form.Item>
                        </Col>
                        <Col>
                            <Spin tip="Uploading..." spinning={startUpdate?true:false}>
                                <Form.Item
                                    name="user_image_update"
                                    label="Photo:"
                                    className="title-Text"
                                >
                               
                                        <Upload
                                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListUpdate}
                                            onChange={onChangeFileUpdate}
                                            // onPreview={onPreviewUpdate}
                                        >
                                            {fileListUpdate.length < 1 && <><Button icon={<UploadOutlined />}>Upload</Button></>}
                                        </Upload>
                                    
                                </Form.Item>
                            </Spin>
                            <p><strong>Better View Resoulation: 960×540, 1280×720, 1366×768, 1600×900</strong></p>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}