import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectMember {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectMember = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectMember) => {
  const memberInfoListForSpeech = useStoreState((state) => state.pages.memberInfoListForSpeech)
  // const districtListFetch = useStoreActions((state) => state.common.districtListFetch);
  // const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);


  const onSelect = (memberId) => {
    if (memberInfoListForSpeech) {
      const thana = memberInfoListForSpeech.find((item) => item.memberId === memberId);
      onChange(thana.memberId);
    }
  };

  // React.useEffect(() => {
  //   if (selected) {
  //     thanaListFetch(selected?.districtId);
  //   }
  // }, [selected])


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Member"
      filterOption={(input, option) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {memberInfoListForSpeech ? (
        memberInfoListForSpeech.map((type, idx) => (
          <Option key={type.memberId} value={type.memberId}>
            {type.memberName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Member</Option>
      )}
    </Select>
  );
};
