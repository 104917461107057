import { DeleteOutlined, EditFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Space, Tooltip, Typography, Upload} from 'antd'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import TableView from '../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';


export default function ImportantLinksInfo() {
    const { Option } = Select;
    const { Title, Paragraph, Text, Link } = Typography;
    const [isModalVisible, setIsModalVisible] = useState<any>();
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const storeImportantLinksInfo = useStoreActions((state) => state.pages.storeImportantLinksInfo);
    const fetchImportantLinksInfoList = useStoreActions((state) => state.pages.fetchImportantLinksInfoList);
    const importantLinksInfoList = useStoreState((state) => state.pages.importantLinksInfoList);
    const updateImportantLinksInfo = useStoreActions((state) => state.pages.updateImportantLinksInfo);
    const deleteImportantLinksInfo = useStoreActions((state) =>state.pages.deleteImportantLinksInfo)
    const [importantLinksId, setImportantLinksId] = useState<any>();


    const handleOk = () => {
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    useEffect(() => {
        fetchImportantLinksInfoList();
    },[])

    const deleteIMortantLinkInfo = (val) => {
        deleteImportantLinksInfo(val);
    }

    const columns = [
        { title: 'Serial', dataIndex: 'serial', key: 'serial', showOnResponse: true, showOnDesktop: true  },
        { title: 'Title', dataIndex: 'title', key: 'title', showOnResponse: true, showOnDesktop: true  },
        { title: 'Link', dataIndex: 'link', key: 'link', showOnResponse: true, showOnDesktop: true  },
        { title: 'Status', dataIndex: 'linkStatusString', key: 'linkStatusString', showOnResponse: true, showOnDesktop: true  },
        { title: 'Action', dataIndex: 'id', key: 'id', showOnResponse: true, showOnDesktop: true ,
        render: (text: any, record: any, index) => (
            <>
                <Space size="middle">
                    <Tooltip title="Edit">
                     <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setImportantLinksId(record?.linkId);
                            updateForm.setFieldsValue({
                                linkUpdate: record?.link,
                                statusUpdate: record?.linkStatusString,
                                serialUpdate: record?.serial,
                                titleUpdate: record?.title,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteIMortantLinkInfo(record?.linkId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            </>
        )},
    ];

    const importnantLinksInfoSubmit = (val) => {
        console.log('val', val);
        let postdata = {
            "link": val?.link,
            "linkStatus": val?.status == "Active" ? 1 : 0,
            "serial": val?.serial,
            "title": val?.title
        }
        storeImportantLinksInfo(postdata);
        form.resetFields();
    }

    const importnantLinksInfoUpdateSubmit = (val) => {
        let postdata = {
            "importantLinkId": importantLinksId,
            "link": val?.linkUpdate,
            "linkStatus": val?.statusUpdate == "Active" ? 1 : 0,
            "serial": val?.serialUpdate,
            "title": val?.titleUpdate
        }
        updateImportantLinksInfo(postdata);
        updateForm.resetFields();
        setIsModalVisible(false);
    }

    return (
        <>
            <Card title="Important Links Info">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:8}}>
                        <Form 
                            layout="vertical" 
                            id='create-class' 
                            form={form} 
                            onFinish={importnantLinksInfoSubmit}
                        >
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="serial"
                                        label="Serial"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write serial" },
                                        ]} 
                                    >
                                        <InputNumber type="text" placeholder="Write serial" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="title"
                                        label="Title"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write title" },
                                        ]} 
                                    >
                                        <Input placeholder="Write title" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="link"
                                        label="Link"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please write link" },
                                        ]} 
                                    >
                                        <Input placeholder="Write link" /> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        className="title-Text"
                                        style={{ width: "100%" }}
                                        rules={[
                                            { required: true, message: "Please select status" },
                                        ]} 
                                    >
                                        <Select placeholder="Select status" >
                                            <Option key={0} value="Active">Active</Option>
                                            <Option key={1} value="Inactive">Deactive</Option>
                                        </Select> 
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                                    <Button type="primary" htmlType='submit' style={{ float:"right" }}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:16}}>
                        <div className="datatable-responsive-demo">
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: importantLinksInfoList,
                                        filterData: importantLinksInfoList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            </div>
                    </Col>
                </Row>
            </Card>

            <Modal title="Important Links Info Update"
                visible={isModalVisible}
                // onOk={handleOk}
                onCancel={handleCancel}
                centered
                okText={"Update"}
                cancelButtonProps={{ style: { display: "none" } }}
                width="50%"
                maskClosable={false}
                okButtonProps={{ form: 'importantLinksUpdate', htmlType: 'submit' }}
            >
                <Form layout="vertical" id='importantLinksUpdate' form={updateForm} onFinish={importnantLinksInfoUpdateSubmit}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="serialUpdate"
                                label="Serial"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write serial" },
                                ]} 
                            >
                                <InputNumber type="text" placeholder="Write serial" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="titleUpdate"
                                label="Title"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write title" },
                                ]} 
                            >
                                <Input placeholder="Write title" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:24}}>
                            <Form.Item
                                name="linkUpdate"
                                label="Link"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please write link" },
                                ]} 
                            >
                                <Input placeholder="Write link" /> 
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span:12}}>
                            <Form.Item
                                name="statusUpdate"
                                label="Status"
                                className="title-Text"
                                style={{ width: "100%" }}
                                rules={[
                                    { required: true, message: "Please select status" },
                                ]} 
                            >
                                <Select placeholder="Select status" >
                                    <Option key={0} value="Active">Active</Option>
                                    <Option key={1} value="Inactive">Inactive</Option>
                                </Select> 
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}